import { createSvgIcon, SvgIconProps } from '@mui/material';

import { FC } from 'react';

export const Error500Icon: FC<SvgIconProps> = createSvgIcon(
  <svg viewBox='0 0 128 128'>
    <g fill='none'>
      <path
        d='M82.7671 21.7143C77.0411 21.7143 70.274 24.8403 70.274 39.1681V43.0756C70.274 45.4202 70.274 47.7647 70.0137 48.8067C69.2329 49.0672 66.6301 49.0672 64.2877 49.0672H62.726V27.4454C62.726 11.8151 57.2603 4 46.3288 4C35.3973 4 29.9315 11.8151 29.9315 27.4454V66C27.589 66 25.2466 66 24.4658 65.7395C24.2055 64.958 24.2055 62.3529 24.2055 60.0084V47.5042V47.2437C24.2055 32.916 17.4384 29.7899 11.9726 29.7899C6.76712 30.0504 0 33.1765 0 47.5042V70.4286C0 83.9748 5.72603 89.9664 19.5205 89.9664H30.4521V124.874C30.4521 126.697 32.0137 128 33.5753 128H59.6027C61.4247 128 62.726 126.437 62.726 124.874V72.7731H75.2192C88.7534 72.7731 94.7397 67.042 94.7397 53.2353V39.1681C95 24.5798 88.2329 21.7143 82.7671 21.7143zM56.4795 121.487H36.9589V89.9664H42.6849C44.5069 89.9664 45.8082 88.4034 45.8082 86.8403C45.8082 85.2773 44.2466 83.7143 42.6849 83.7143H19.5205C9.36986 83.7143 6.50685 80.8487 6.50685 70.6891V47.7647C6.50685 36.8235 10.411 36.8235 12.2329 36.8235C14.0548 36.8235 17.9589 36.8235 17.9589 47.5042V47.7647V60.5294C17.9589 71.4706 19.5205 72.7731 30.1918 72.7731H42.1644C43.9863 72.7731 45.2877 71.2101 45.2877 69.6471C45.2877 68.084 43.726 66.521 42.1644 66.521H36.4384V27.4454C36.4384 10.5126 43.2055 10.5126 46.3288 10.5126C49.1918 10.5126 56.2192 10.5126 56.2192 27.4454V121.487H56.4795zM88.4932 53.2353C88.4932 63.395 85.6301 66.2605 75.4795 66.2605H62.9863V55.5798H64.5479C75.4794 55.5798 76.7808 54.0168 76.7808 43.0756V39.1681C76.7808 28.2269 80.6849 28.2269 82.5069 28.2269C84.3288 28.2269 88.2329 28.2269 88.2329 39.1681V53.2353H88.4932z'
        fill='currentColor'
        role='presentation'
      />
      <path
        d='M127.5 117.2C127.5 116.7 127.2 116.2 126.7 116C126.2 115.8 125.7 115.9 125.3 116.3C125.3 116.3 121.7 119.8 117.3 120.4C116.8 118.4 115.3 116.8 113.3 116.2C114 115.2 114.5 114 114.5 112.7C114.5 109.5 111.9 106.9 108.7 106.9H101.1C101.4 104.3 102 98.3 102 94.8C102 90.5 98.6 89 96.2 89H90.8C86.5 89 85 92.4 85 94.8C85 95.4 85.5 95.9 86.1 96L94 96.9V107.1C91.2 107.5 89 109.9 89 112.8C89 114.1 89.4 115.3 90.2 116.3C87.8 116.9 86 119.1 86 121.8C86 125 88.6 127.6 91.8 127.6H111.4H111.8H117.3C125.4 127.5 127.5 120.8 127.5 117.2zM87.7 93.7C88 92.7 88.7 91.5 90.8 91.5H96.3C96.6 91.5 99.5 91.6 99.5 94.7C99.5 98.2 98.9 104.4 98.6 106.9H96.5V95.8C96.5 95.2 96 94.6 95.4 94.6L87.7 93.7zM94.8 109.5H108.8C110.6 109.5 112 111 112 112.7C112 114.4 110.5 116 108.8 116H94.8C93 116 91.6 114.5 91.6 112.8C91.6 111.1 93 109.5 94.8 109.5zM111.3 125H91.8C90 125 88.6 123.5 88.6 121.8C88.6 120.1 90.1 118.6 91.8 118.6H111.8C113.6 118.6 115 120.1 115 121.8C115 123.5 113.5 125 111.8 125H111.3zM117.2 125H116.4C116.8 124.4 117.1 123.7 117.3 122.9C120.1 122.6 122.6 121.4 124.4 120.2C123.6 122.4 121.7 125 117.2 125z'
        fill='currentColor'
        role='presentation'
      />
    </g>
  </svg>,
  'Error500Icon'
);

Error500Icon.displayName = 'Error500Icon';
