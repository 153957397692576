import { maskEmail } from '@vestwell-frontend/helpers';

import { FC, useMemo } from 'react';

export type FormatEmailProps = {
  mask?: boolean;
  showPlaceholder?: boolean;
  value: string;
};

export const FormatEmail: FC<FormatEmailProps> = ({ value = '', ...props }) => {
  const formatted = useMemo(() => {
    return value
      ? props.mask
        ? maskEmail(value)
        : value
      : props.showPlaceholder
        ? '--'
        : '';
  }, [props.showPlaceholder, value]);

  return <>{formatted}</>;
};

FormatEmail.displayName = 'FormatEmail';
