import { WizardStep } from '@vestwell-frontend/ui';

export type MfaNudgeState = {
  hasSetupMfaMethod?: boolean;
  hasSetupMfaSkipped?: boolean;
  hasSetupMfaVerified?: boolean;
  isMfaEnabled?: boolean;
  isMfaMethodSelected?: boolean;
  isUserReadyToEnableMfa?: boolean;
  mfaMethod?: 'app' | 'phone';
  mfaMethodSubType?: 'text' | 'voice';
  mfaPhone?: string;
};

export const mfaNudge: {
  initialState: MfaNudgeState;
  steps: WizardStep<MfaNudgeState>[];
} = {
  initialState: {
    hasSetupMfaMethod: false,
    hasSetupMfaSkipped: false,
    hasSetupMfaVerified: false,
    isMfaEnabled: false,
    isMfaMethodSelected: false,
    isUserReadyToEnableMfa: false,
    mfaMethod: undefined,
    mfaPhone: ''
  },
  steps: [
    {
      title: 'Setup Two-Factor Authentication',
      url: '/mfa',
      when: state => !state.isMfaEnabled && !state.isUserReadyToEnableMfa
    },
    {
      title: 'Select A Method',
      url: '/mfa/method',
      when: state => !state.isMfaMethodSelected && state.isUserReadyToEnableMfa
    },
    {
      title: 'Setup Authentication App',
      url: '/mfa/app',
      when: state => state.isMfaMethodSelected && state.mfaMethod === 'app'
    },
    {
      title: 'Enter Phone Number',
      url: '/mfa/phone',
      when: state => state.isMfaMethodSelected && state.mfaMethod === 'phone'
    },
    {
      title: 'Verify Security Code',
      url: '/mfa/verify',
      when: state => state.hasSetupMfaMethod
    },
    {
      title: 'Two-Factor Authentication Is Now Enabled',
      url: '/mfa/completed',
      when: state => state.hasSetupMfaVerified
    }
  ]
};
