import { Alert } from '@mui/material';

import cn from 'clsx';
import {
  Formik,
  FormikErrors,
  Form as FormikForm,
  getIn,
  useFormikContext,
  yupToFormErrors
} from 'formik';
import type { FormikConfig, FormikContextType } from 'formik';
import {
  createContext,
  Dispatch,
  FC,
  forwardRef,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDebounce, useToggle, useUnmount, useUpdateEffect } from 'react-use';

export const FORM_SUBMIT_ERROR = 'error';

export type FormConfirmProps = {
  confirm: () => void | Promise<void>;
  dismiss: () => void | Promise<void>;
  open: boolean;
  values?: any;
};

export type FormProps<T> = Omit<
  FormikConfig<T>,
  'initialValues' | 'innerRef' | 'onSubmit'
> & {
  'aria-label'?: string;
  'aria-labelledby'?: string;
  'data-component'?: string;
  autoComplete?: string;
  children?: ReactNode;
  /** className to apply to the wrapping form element */
  className?: string;
  /** component used for confirmation */
  Confirm?: FC<FormConfirmProps>;
  initialValues?: any;
  /** onChange called when values in the form changed */
  onChange?:
    | Dispatch<SetStateAction<T | undefined>>
    | ((values: T) => void | Promise<void>);
  /** onChange called when values in the form changed */
  onErrors?:
    | Dispatch<SetStateAction<FormikErrors<T> | undefined>>
    | ((errors: FormikErrors<T>) => void | Promise<void>);
  /** onRawErrors called when raw yup errors are updated */
  onRawErrors?: (errors: FormikErrors<T> | null) => void;
  /** called when submitting state changes */
  onSubmitting?: (isSubmitting: boolean) => void | Promise<void>;
  /** called when resetting the form */
  onSubmit?: FormikConfig<T>['onSubmit'];
  /** called when submitting the form */
  onReset?: FormikConfig<T>['onReset'];
  /** onValidation called when form validation state changed */
  onValidation?:
    | Dispatch<SetStateAction<boolean | undefined>>
    | ((valid: boolean) => void | Promise<void>);
  /** prevent onChange from getting called after mount */
  preventOnChangeOnMount?: boolean;
  /** additional context to pass to yup validationSchema; accessed via this.options.context */
  validationContext?: Record<string, unknown>;
};

/**
 * this is a wrapper for the Confirm prop/component; it
 * implements to subsequent submission post confirmation
 */
const FormConfirm: FC<
  FormConfirmProps & {
    isConfirmed?: boolean;
    Confirm: FC<FormConfirmProps>;
  }
> = memo(({ Confirm, isConfirmed, ...props }) => {
  const ctx = useFormikContext<any>();

  useUpdateEffect(() => {
    if (!props.open && isConfirmed) {
      (async () => {
        await ctx.handleSubmit();
      })();
    }
  }, [isConfirmed]);

  return <Confirm {...props} values={ctx.values} />;
});
/**
 * this is a workaround for adding an onChange callback to Form
 * unfortunately, formik does not provide onChange callback (only <form onChange>)
 */
const FormChangeHandler: FC<{
  onChange: FormProps<any>['onChange'];
  preventOnChangeOnMount?: boolean;
}> = memo(props => {
  const ctx = useFormikContext<any>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const $timeout = setTimeout(() => {
      setMounted(true);
    }, 10);
    return () => {
      clearTimeout($timeout);
    };
  }, []);

  const [, cancel] = useDebounce(
    () => {
      const shouldCall =
        !props.preventOnChangeOnMount ||
        (props.preventOnChangeOnMount && mounted);

      if (props.onChange && shouldCall) {
        props.onChange(ctx.values);
      }
    },
    10,
    [ctx.values]
  );

  useUnmount(() => {
    cancel();
  });

  return null;
});

/**
 * this is a workaround for adding an onValidation callback to Form
 * unfortunately, formik does not provide onValidation callback
 */
const FormValidationHandler: FC<{
  onValidation: FormProps<any>['onValidation'];
}> = memo(props => {
  const ctx = useFormikContext<any>();

  useEffect(() => {
    props.onValidation(ctx.isValid);
  }, [ctx.isValid]);

  return null;
});

/**
 * this is a workaround for adding an onErrors callback to Form
 * unfortunately, formik does not provide onErrors callback
 */
const FormErrorHandler: FC<{ onErrors: FormProps<any>['onErrors'] }> = memo(
  props => {
    const ctx = useFormikContext<any>();

    useEffect(() => {
      if (props.onErrors) {
        props.onErrors(ctx.errors);
      }
    }, [ctx.errors]);

    return null;
  }
);

/**
 * this is a workaround to re-validate form when schema changes
 */
const FormValidationSchemaChanged: FC<{ validationSchema: unknown }> = memo(
  props => {
    const ctx = useFormikContext<any>();

    useUpdateEffect(() => {
      ctx.validateForm();
    }, [props.validationSchema]);

    return null;
  }
);

export const IsRequiredFieldContext = createContext({});

export const Form = forwardRef<FormikContextType<any>, FormProps<any>>(
  (
    {
      Confirm,
      enableReinitialize = true,
      initialValues = {},
      validateOnBlur = true,
      validateOnChange = true,
      validateOnMount = true,
      ...props
    },
    ref
  ) => {
    const [isConfirmed, toggleIsConfirmed] = useToggle(false);
    const [showConfirm, toggleShowConfirm] = useToggle(false);

    const onConfirm = useCallback(() => {
      toggleIsConfirmed(true);
      toggleShowConfirm(false);
    }, []);

    const onDismiss = useCallback(() => {
      toggleIsConfirmed(false);
      toggleShowConfirm(false);
    }, []);

    /** wrapper around callback to provide validationContext to the yup schema */
    const onValidate = useCallback(
      async values => {
        if (props.validate) {
          return props.validate(values);
        }

        try {
          await props.validationSchema.validate(values, {
            abortEarly: false,
            context: {
              ...(props.validationContext || {})
            }
          });
        } catch (e) {
          if (props.onRawErrors) {
            props.onRawErrors(e);
          }

          return yupToFormErrors(e);
        }
      },
      [
        props.onRawErrors,
        props.validate,
        props.validationContext,
        props.validationSchema
      ]
    );

    const onReset = useCallback(
      (values, ctx) => {
        if (Confirm && isConfirmed) {
          toggleIsConfirmed(false);
        }

        if (props.onReset) {
          return props.onReset(values, ctx);
        }
      },
      [Confirm, isConfirmed, props.onReset]
    );

    const onSubmit = useCallback(
      async (values, ctx) => {
        if (!isConfirmed && Confirm) {
          toggleShowConfirm(true);
          ctx.setSubmitting(false);
          return;
        }

        const result = props.onSubmit
          ? await props.onSubmit(values, ctx)
          : undefined;

        toggleIsConfirmed(false);

        return result;
      },
      [Confirm, isConfirmed, props.onSubmit]
    );

    const isRequiredFieldValue = useMemo(() => {
      if (!props.validationSchema) {
        return {};
      }

      const fields = props.validationSchema.describe().fields ?? {};

      return Object.keys(fields).reduce(
        (acc, field) => ({
          ...acc,
          [field]: !!getIn(fields, field)?.tests?.find(
            test => test.name === 'required'
          )
        }),
        {}
      );
    }, []);

    const ariaLabel = props['aria-label'];
    const ariaLabelledBy = props['aria-labelledby'];

    return (
      <IsRequiredFieldContext.Provider value={isRequiredFieldValue}>
        <Formik
          autoComplete={props.autoComplete}
          enableReinitialize={enableReinitialize}
          initialTouched={props.initialTouched}
          initialValues={initialValues}
          innerRef={ref as any}
          onReset={onReset}
          onSubmit={onSubmit}
          validate={onValidate}
          validateOnBlur={validateOnBlur}
          validateOnChange={validateOnChange}
          validateOnMount={validateOnMount}>
          <FormikForm
            {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
            {...(ariaLabelledBy ? { 'aria-labelledby': ariaLabel } : {})}
            autoComplete={props.autoComplete}
            className={props.className}
            data-component={props['data-component'] || 'Form'}
            data-testid={props['data-testid']}>
            {props.children}
            {Confirm && (
              <FormConfirm
                Confirm={Confirm}
                confirm={onConfirm}
                dismiss={onDismiss}
                isConfirmed={isConfirmed}
                open={showConfirm}
              />
            )}
            {props.onValidation && (
              <FormValidationHandler onValidation={props.onValidation} />
            )}
            {props.onChange && (
              <FormChangeHandler
                onChange={props.onChange}
                preventOnChangeOnMount={props.preventOnChangeOnMount}
              />
            )}
            {props.onErrors && <FormErrorHandler onErrors={props.onErrors} />}
            <FormValidationSchemaChanged
              validationSchema={props.validationSchema}
            />
          </FormikForm>
        </Formik>
      </IsRequiredFieldContext.Provider>
    );
  }
);

export type FormErrorsProps = {
  className?: string;
  renderPlaceholder?: ReactNode | true;
};

export const FormErrors: FC<FormErrorsProps> = props => {
  const { errors } = useFormikContext<any>();

  if (!errors.form && !props.renderPlaceholder) return null;

  if (Array.isArray(errors.form)) {
    return (
      <>
        {errors.form.map(error => (
          <Alert
            className={cn('w-fit-content mb-4', props.className)}
            key={error}
            severity='error'>
            {error}
          </Alert>
        ))}
      </>
    );
  } else if (typeof errors.form === 'string') {
    return (
      <Alert
        className={cn('w-fit-content mb-4', props.className)}
        severity='error'>
        {errors.form}
      </Alert>
    );
  } else if (props.renderPlaceholder === true) {
    return <span aria-hidden='true' className='block h-6' />;
  } else {
    // handle generic validation results, e.g. `true` without messaging
    return null;
  }
};

Form.displayName = 'Form';
