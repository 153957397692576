import { createSvgIcon, SvgIconProps } from '@mui/material';

import { FC } from 'react';

export const TimerIcon: FC<SvgIconProps> = createSvgIcon(
  <svg fill='none' viewBox='0 0 120 121'>
    <rect
      fill='white'
      height='8'
      rx='2'
      stroke='#033952'
      strokeWidth='1.5'
      transform='rotate(90 64 13.5)'
      width='15'
      x='64'
      y='13.5'
    />
    <rect
      height='58'
      rx='2'
      stroke='#DDC04A'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      width='103'
      x='9'
      y='31.5'
    />
    <circle cx='86' cy='34.5' fill='white' r='6' />
    <circle cx='86' cy='34.5' fill='url(#paint0_linear_1882_12488)' r='6' />
    <path
      clipRule='evenodd'
      d='M12.25 37.5C12.25 35.9812 13.4812 34.75 15 34.75H106C107.519 34.75 108.75 35.9812 108.75 37.5V83.5C108.75 85.0188 107.519 86.25 106 86.25H15C13.4812 86.25 12.25 85.0188 12.25 83.5V37.5ZM15 36.25C14.3096 36.25 13.75 36.8096 13.75 37.5V83.5C13.75 84.1904 14.3096 84.75 15 84.75H106C106.69 84.75 107.25 84.1904 107.25 83.5V37.5C107.25 36.8096 106.69 36.25 106 36.25H15Z'
      fill='url(#paint1_linear_1882_12488)'
      fillRule='evenodd'
    />
    <circle cx='83' cy='74.5' fill='white' r='8' />
    <circle cx='60' cy='63.5' fill='white' r='40' />
    <circle cx='60' cy='65.5' fill='white' r='40' />
    <circle cx='60' cy='65.5' fill='url(#paint2_linear_1882_12488)' r='40' />
    <circle cx='60' cy='63.5' r='40' stroke='#033952' strokeWidth='1.5' />
    <path
      clipRule='evenodd'
      d='M61 31.25C61 30.8358 60.6642 30.5 60.25 30.5C42.1627 30.5 27.5 45.1627 27.5 63.25C27.5 81.3373 42.1627 96 60.25 96C78.3373 96 93 81.3373 93 63.25C93 62.8358 92.6642 62.5 92.25 62.5C91.8358 62.5 91.5 62.8358 91.5 63.25C91.5 80.5089 77.5089 94.5 60.25 94.5C42.9911 94.5 29 80.5089 29 63.25C29 46.2418 42.5876 32.4072 59.5 32.0088V63.25C59.5 63.6642 59.8358 64 60.25 64C60.6642 64 61 63.6642 61 63.25V31.25Z'
      fill='url(#paint3_linear_1882_12488)'
      fillRule='evenodd'
    />
    <rect fill='white' height='8' width='26' x='47' y='12.5' />
    <rect
      fill='url(#paint4_linear_1882_12488)'
      height='8'
      width='26'
      x='47'
      y='12.5'
    />
    <rect
      height='8'
      rx='2'
      stroke='#033952'
      strokeWidth='1.5'
      width='26'
      x='47'
      y='10.5'
    />
    <circle cx='86' cy='33.5' r='6' stroke='#033952' strokeWidth='1.5' />
    <defs>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint0_linear_1882_12488'
        x1='86'
        x2='86'
        y1='28.5'
        y2='37.1842'>
        <stop stopColor='#E9F0F9' stopOpacity='0.5' />
        <stop offset='1' stopColor='#DBE0E7' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint1_linear_1882_12488'
        x1='60.5'
        x2='60.5'
        y1='34.75'
        y2='86.25'>
        <stop stopColor='white' stopOpacity='0' />
        <stop offset='0.635417' stopColor='#2C8FC5' stopOpacity='0.09' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint2_linear_1882_12488'
        x1='60'
        x2='60'
        y1='25.5'
        y2='83.3947'>
        <stop stopColor='#E9F0F9' stopOpacity='0.5' />
        <stop offset='1' stopColor='#DBE0E7' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint3_linear_1882_12488'
        x1='60.25'
        x2='90.75'
        y1='63.25'
        y2='63.25'>
        <stop stopColor='#0679AF' />
        <stop offset='1' stopColor='#0679AF' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        gradientUnits='userSpaceOnUse'
        id='paint4_linear_1882_12488'
        x1='60'
        x2='60'
        y1='12.5'
        y2='18.2895'>
        <stop stopColor='#E9F0F9' stopOpacity='0.5' />
        <stop offset='1' stopColor='#DBE0E7' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>,
  'TimerIcon'
);

TimerIcon.displayName = 'TimerIcon';
