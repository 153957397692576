export const routes = {
  ACCOUNT_LOCKED: '/locked',
  ACCOUNT_SETUP: '/setup/account',
  AUTHORIZATION: '/authorization',
  EMPLOYER_REGISTRATION: '/register/employer',
  FORGOT_PASSWORD: '/password/forgot',
  LOGIN_AND_SECURITY: '/login-and-security',
  LOGIN_VERIFICATION: '/auth/api/v1/login/verification',
  LOGOUT: '/logout',
  MFA_NUDGE: '/mfa',
  MFA_VERIFICATION: '/verification',
  MOBILE_SIGN_IN: '/auth/index.html',
  PERSONAS: '/personas',
  REGISTRATION: '/register',
  RESET_PASSWORD: '/password/reset',
  RESET_PASSWORD_EXPIRED: '/password/reset-expired',
  SIGN_IN: '/'
} as const;
