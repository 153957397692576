import { useRef } from 'react';
import { useNavigate as useNavigateFn } from 'react-router-dom';

/**
 * wrapper around react-dom-router useNavigation
 * to provide support for relative pathing:
 *
 * e.g. ../../foo
 */
export const useNavigate = (): ((path: string | -1) => void) => {
  const navigate = useNavigateFn();

  const fn = useRef((path: string | -1) => {
    const backParts =
      typeof path === 'string' &&
      path.includes('../') &&
      path.split('../').filter(part => !part);
    const urlPaths = window.location.pathname.split('/');

    const url = backParts
      ? [
          ...(urlPaths.splice(
            urlPaths.length - backParts.length,
            backParts.length
          ) && urlPaths),
          path.replaceAll('../', '')
        ].join('/')
      : path;

    return typeof url === 'string' ? navigate(url) : window.history.back();
  });

  return fn.current;
};
