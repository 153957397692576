import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

export const setMobileHeaders = async axios => {
  // This value is set by webpack.config.ts in mobile-app
  const baseUrl = await SecureStoragePlugin.get({ key: 'BASE_API_URL' });
  const whitelabelSubdomain = await SecureStoragePlugin.get({
    key: 'WHITELABEL_SUBDOMAIN'
  });

  axios.defaults.baseURL = baseUrl?.value;
  axios.defaults.headers.common['x-mobile-subdomain'] =
    whitelabelSubdomain?.value;
  return axios;
};
