import {
  AccountBalanceOutlined,
  ChevronRight,
  OpenInNewOutlined,
  PersonOutlineOutlined
} from '@mui/icons-material';
import {
  Alert,
  Avatar,
  AvatarProps,
  Box,
  Card,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListProps,
  ListSubheader,
  ListSubheaderProps,
  Stack,
  styled,
  useMediaQuery
} from '@mui/material';
import {
  DataGrid,
  GridSortModel,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import {
  ParticipantPersona,
  StateSaverPersona,
  useGetPersonas,
  useGetSessionInfo
} from '@sentinel/hooks';
import { useAuth } from '@vestwell-frontend/elements';
import { broadcastToApp, isEmbeddedBrowser } from '@vestwell-frontend/helpers';
import { useDocumentTitle, usePostMutation } from '@vestwell-frontend/hooks';
import {
  AccountIcon,
  ContributionsIcon, // DataGrid,
  DataGridProps,
  PLACEHOLDER,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  Tabs,
  TabsProps,
  Text
} from '@vestwell-frontend/ui';

import { groupBy, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMeasure } from 'react-use';

import { useBackButtonBehavior, useNav } from '../hooks';
import { routes } from '../router/routes';

const StyledContributionsIcon = styled(ContributionsIcon)(({ theme }) => ({
  color: '#2C8FC5',
  height: theme.spacing(8),
  width: theme.spacing(8)
}));

const StyledAccountIcon = styled(AccountIcon)(({ theme }) => ({
  height: theme.spacing(12),
  width: theme.spacing(8)
}));

const planType = {
  '401k': {
    avatarBgColor: '#EBF4F8',
    avatarIcon: <StyledContributionsIcon />,
    nameForParticipant: 'Retirement Savings 401(k)',
    nameForSponsor: '401(k)'
  },
  '403b': {
    avatarBgColor: '#EBF4F8',
    avatarIcon: <StyledContributionsIcon />,
    nameForParticipant: 'Retirement Savings 403(b)',
    nameForSponsor: '403(b)'
  },
  ESA: {
    avatarBgColor: '#FFF2E9',
    avatarIcon: <StyledAccountIcon color='alert' />,
    nameForParticipant: 'Emergency Savings',
    nameForSponsor: 'Emergency Savings'
  },
  'Solo 401k': {
    avatarBgColor: '#EBF4F8',
    avatarIcon: <StyledContributionsIcon />,
    nameForParticipant: 'Retirement Savings Solo 401(k)',
    nameForSponsor: 'Solo 401(k)'
  },
  'Starter 401k': {
    avatarBgColor: '#EBF4F8',
    avatarIcon: <StyledContributionsIcon />,
    nameForParticipant: 'Retirement Savings Starter 401(k)',
    nameForSponsor: 'Starter 401k'
  },
  'State IRA': {
    avatarBgColor: '#F2F2F2',
    avatarIcon: <AccountBalanceOutlined color='grey100' />,
    nameForParticipant: 'State IRA',
    nameForSponsor: 'State IRA'
  },
  cash_balance: {
    avatarBgColor: '#EBF4F8',
    avatarIcon: <StyledContributionsIcon />,
    nameForParticipant: 'Cash Balance',
    nameForSponsor: 'Cash Balance'
  },
  vss: {
    avatarBgColor: '#F2F2F2',
    avatarIcon: <AccountBalanceOutlined color='grey100' />,
    nameForParticipant: 'Cash Balance',
    nameForSponsor: 'Cash Balance'
  }
} as const;

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => !['customBgColor'].includes(prop.toString())
})<AvatarProps & { customBgColor?: string }>(({ customBgColor, theme }) => ({
  backgroundColor: customBgColor || theme.palette.grey700.main,
  marginRight: theme.spacing(4)
}));

const StyledList = styled(List)<ListProps>(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  border: `${theme.spacing(0.25)} solid ${theme.palette.grey600.main}`,
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[24],
  marginBottom: theme.spacing(6),
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(160)
  }
}));

const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(
  ({ theme }) => ({
    '&:last-of-type': {
      borderBottom: `${theme.spacing(0.25)} solid transparent`
    },
    alignItems: 'center',
    borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.grey700.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4)
  })
);

const StyledListSubheader = styled(ListSubheader)<ListSubheaderProps>(
  ({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.aliceBlue.main,
    borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.grey700.main}`,
    borderRadius: theme.spacing(1, 1, 0, 0),
    display: 'flex',
    height: theme.spacing(9.5)
  })
);

const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  padding: theme.spacing(0, 4),
  width: '100%'
}));

const StyledTabList = styled(TabList)<TabListProps>({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
});

const StyledTabPanel = styled(TabPanel)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

const StyledAlert = styled(Alert)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(160)
  }
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(0, 2)
}));

const StyledCircularProgress = styled(CircularProgress)(() => ({
  margin: 'auto'
}));

const columns: DataGridProps['columns'] = [
  {
    field: 'sponsorId',
    flex: 0.3,
    headerName: 'ID',
    renderCell: params => {
      return (
        <Text color='primary' mb={0} variant='i2'>
          {params.row.recordKeeperId === 2
            ? (params.row.externalSponsorPlanId ?? PLACEHOLDER)
            : (params.row.sponsorPlanId ?? PLACEHOLDER)}
        </Text>
      );
    },
    sortable: false,
    valueGetter: (value, row) => {
      return row.recordKeeperId === 2
        ? (row.externalSponsorPlanId ?? PLACEHOLDER)
        : (row.sponsorPlanId ?? PLACEHOLDER);
    }
  },
  {
    field: 'externalSponsorPlanId',
    headerName: 'externalSponsorPlanId',
    sortable: false
  },
  {
    field: 'planType',
    flex: 0.3,
    headerName: 'Type',
    renderCell: params => {
      return params.row.recordKeeperId === 2
        ? `Voya ${planType[params.row.planType]?.nameForSponsor}`
        : planType[params.row.planType]?.nameForSponsor;
    },
    sortable: false
  },
  {
    field: 'company',
    flex: 1,
    headerName: 'Employer name',
    sortable: false,
    width: 200
  }
];

export function PersonasPage() {
  useDocumentTitle('Your Accounts');

  useBackButtonBehavior();

  const [params] = useSearchParams();

  const { setToken } = useAuth();

  const nav = useNav();

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [entity, setEntity] = useState(null);
  const [isEmbedded, setIsEmbedded] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [ref, dimensions] = useMeasure();

  useGetSessionInfo('user', {
    query: {
      onSuccess: async user => {
        if (user?.hasMfaNudge && !user.isMfaEnabled) {
          nav.to(routes.MFA_NUDGE);
          return;
        }

        if (user?.isMfaEnabled && !user.isMfaVerified) {
          nav.to(routes.MFA_VERIFICATION);
          return;
        }

        if (user?.postLoginPath) {
          nav.external(user.postLoginPath);
          return;
        }

        if (params.get('redirect') && user.entity) {
          setEntity({
            entityId: user.entity?.id,
            entityType: user.type,
            hasPersona: true
          });
        }

        if (
          //@ts-expect-error
          !user?.hasPersona &&
          (user?.advisorId || user?.participantId || user?.sponsorId)
        ) {
          setEntity({
            ...(user?.advisorId && user?.type === 'advisor'
              ? {
                  entityId: user?.advisorId,
                  entityType: 'advisor'
                }
              : user?.participantId && user?.type === 'participant'
                ? {
                    entityId: user?.participantId,
                    entityType: 'participant'
                  }
                : {
                    entityId: user?.sponsorId,
                    entityType: 'sponsor'
                  }),
            hasPersona: true
          });
        }
      },
      suspense: true,
      useErrorBoundary: true
    }
  });

  const personas = useGetPersonas(null, {
    query: {
      onSuccess: personas => {
        if (
          personas?.count === 1 &&
          !(
            personas?.stateSavers?.length === 1 &&
            'error' in personas.stateSavers[0]
          )
        ) {
          setEntity({
            ...(personas?.advisors?.[0]
              ? {
                  entityId: personas?.advisors?.[0].advisorId,
                  entityType: 'advisor'
                }
              : personas?.participants?.[0]
                ? {
                    entityId: personas?.participants?.[0].participantId,
                    entityType: 'participant'
                  }
                : personas?.sponsors?.[0]
                  ? {
                      entityId: personas?.sponsors?.[0].sponsorId,
                      entityType: 'sponsor'
                    }
                  : {
                      entityId: personas?.stateSavers?.[0].stateSaverId,
                      entityType: 'state_saver'
                    }),
            hasPersona: true
          });
        }
      },
      suspense: true,
      useErrorBoundary: true
    }
  });

  const [postEntity, postEntityResult] = usePostMutation(
    '/auth/api/v1/login/entity',
    {},
    {
      onSuccess: async data => {
        setToken(data?.token);
        if (isEmbedded) {
          broadcastToApp('CLOSE', data?.token);
        } else {
          nav.external(data?.homeUrl);
        }
      }
    }
  );

  const advisors = useMemo(() => {
    const grouped = groupBy(personas.data?.advisors || [], 'company');

    return Object.keys(grouped)
      .sort()
      .map(company => ({
        company,
        users: grouped[company]
      }));
  }, [personas.data]);

  const participants = useMemo(() => {
    const groupedNames = [
      ...(personas.data?.stateSavers || []),
      ...(personas.data?.participants || [])
    ].reduce((acc, obj) => {
      const key = obj.name?.toLowerCase().replace(/[^a-z0-9]/g, ''); // Convert to lowercase, remove spaces, and remove symbols
      acc[key] = (acc[key] || []).concat(obj);
      return acc;
    }, {});

    return Object.keys(groupedNames)
      .sort()
      .map(name => {
        const companies: {
          company: string;
          users: ParticipantPersona[] | StateSaverPersona[];
        }[] = sortBy(
          Object.entries(groupBy(groupedNames[name], 'company')),
          'company'
        ).map(([company, users]) => ({
          company,
          users
        }));

        if (companies.length > 0 && companies[0].users[0].name) {
          return {
            companies,
            name: companies[0].users[0].name
          };
        }
      })
      .filter(Boolean);
  }, [personas.data]);

  const sponsors = useMemo(() => {
    const grouped = groupBy(personas.data?.sponsors || [], 'company');

    const companies = Object.keys(grouped).sort();

    return companies.map(company => ({
      company,
      users: grouped[company]
    }));
  }, [personas.data]);

  const isTheOnlyTab = useMemo(
    () =>
      (personas.data?.advisors?.length ? 1 : 0) +
        (personas.data?.sponsors?.length ? 1 : 0) +
        (personas.data?.participants?.length ||
        personas.data?.stateSavers?.length
          ? 1
          : 0) ===
      1,
    [personas.data]
  );

  const onClick = useCallback(
    async (entity: { entityId: number; entityType: string }) => {
      await postEntity({ ...entity, hasPersona: true });
    },
    []
  );

  const onRowClick = params =>
    onClick({
      entityId: params.row.sponsorId,
      entityType: params.row.type
    });

  // doing this in useEffect to avoid headers conflict in react query and make sure that order of calls is correct
  useEffect(() => {
    if (entity) {
      (async () => {
        await postEntity(entity);
      })();
    }
  }, [entity]);

  useEffect(() => {
    isEmbeddedBrowser().then(result => setIsEmbedded(result));
  }, []);

  return (
    <Stack alignItems='center' flexGrow={1} ref={ref} width='100%'>
      {(personas.data?.count < 2 &&
        !(
          personas.data?.stateSavers?.length === 1 &&
          'error' in personas.data.stateSavers[0]
        )) ||
      postEntityResult.isLoading ||
      postEntityResult.isSuccess ? (
        <StyledCircularProgress />
      ) : (
        <>
          <Text
            align='center'
            color='grey50'
            pb={2}
            pt={6}
            variant='c2'
            width='100%'>
            Your Accounts
          </Text>
          {personas.data?.count < 2 &&
            personas.data?.stateSavers?.length === 1 &&
            'error' in personas.data.stateSavers[0] && (
              <Stack alignItems='center'>
                <StyledAlert severity='warning'>
                  Due to technical issues, access to State Saving Accounts (IRA,
                  529 and Able) is temporarily unavailable. Please try again
                  after some time.
                </StyledAlert>
              </Stack>
            )}
          <StyledTabs
            defaultIndex={advisors.length ? 0 : participants.length ? 1 : 2}
            key={advisors.length ? 0 : participants.length ? 1 : 2}
            variant='small'>
            {isTheOnlyTab ? undefined : (
              <StyledTabList>
                {advisors.length ? (
                  <Tab title='Advisor' value={0} />
                ) : undefined}
                {participants.length ? (
                  <Tab title='Personal' value={1} />
                ) : undefined}
                {sponsors.length ? (
                  <Tab title='Employer' value={2} />
                ) : undefined}
              </StyledTabList>
            )}
            {advisors.length ? (
              <StyledTabPanel value={0}>
                {advisors.map(advisor => (
                  <StyledList
                    aria-labelledby={`${advisor.company}`}
                    component='nav'
                    data-company={advisor.company}
                    key={advisor.company}
                    subheader={
                      <StyledListSubheader
                        component='div'
                        data-company={advisor.company}
                        id={`${advisor.company}`}>
                        <Text color='grey100' mb={0} variant='g2'>
                          {advisor.company}
                        </Text>
                      </StyledListSubheader>
                    }>
                    {advisor.users.map(user => (
                      <StyledListItemButton
                        data-company={user.company}
                        data-name={user.name}
                        data-testid={user.advisorId}
                        key={user.advisorId}
                        onClick={() =>
                          onClick({
                            entityId: user.advisorId,
                            entityType: user.type
                          })
                        }>
                        <Stack alignItems='center' direction='row'>
                          <StyledAvatar>
                            <PersonOutlineOutlined color='grey100' />
                          </StyledAvatar>
                          <div>
                            <Text color='grey50' mb={0} variant='e1'>
                              {user.name}
                            </Text>
                            <Text
                              color='grey100'
                              component='div'
                              mb={0}
                              variant='i1'>
                              ID: {user.advisorId}
                            </Text>
                          </div>
                        </Stack>
                        <ChevronRight color='primary' />
                      </StyledListItemButton>
                    ))}
                  </StyledList>
                ))}
              </StyledTabPanel>
            ) : undefined}
            {participants.length ? (
              <StyledTabPanel value={1}>
                {participants.map(participant => (
                  <Stack
                    alignItems='center'
                    key={participant.name}
                    width='100%'>
                    <Text
                      color='grey50'
                      data-component='listHeader'
                      data-name={participant.name}
                      id={`${participant.name}`}
                      variant='e2'>
                      {participant.name}
                    </Text>
                    {participant.companies.map(group => (
                      <StyledList
                        aria-labelledby={`${participant.name} ${group.company}`}
                        component='nav'
                        data-company={group.company}
                        data-name={participant.name}
                        key={`${participant.name} ${group.company}`}
                        subheader={
                          <StyledListSubheader
                            component='div'
                            data-company={group.company}
                            id={`${group.company}`}>
                            <Text color='grey100' mb={0} variant='g2'>
                              {group.company}
                            </Text>
                          </StyledListSubheader>
                        }>
                        {group.users.map(user => (
                          <StyledListItemButton
                            data-plantype={
                              planType[user.planType]?.nameForParticipant ??
                              user.planType
                            }
                            data-testid={user.participantId}
                            key={user.participantId}
                            onClick={() =>
                              onClick({
                                entityId: user.participantId
                                  ? user.participantId
                                  : user.stateSaverId,
                                entityType: user.type
                              })
                            }>
                            <Stack alignItems='center' direction='row'>
                              <StyledAvatar
                                customBgColor={
                                  planType[user.planType]?.avatarBgColor
                                }>
                                {planType[user.planType]?.avatarIcon || (
                                  <PersonOutlineOutlined color='grey100' />
                                )}
                              </StyledAvatar>
                              <div>
                                <Text color='grey50' mb={0} variant='e1'>
                                  {user.planType === 'vss'
                                    ? `${user.planName}${
                                        user.plansCount > 1 ? ' and others' : ''
                                      }`
                                    : (planType[user.planType]
                                        ?.nameForParticipant ?? user.planType)}
                                </Text>
                                {user.planType !== 'vss' && (
                                  <Text
                                    color='grey100'
                                    component='div'
                                    mb={0}
                                    variant='i1'>
                                    ID: {user.participantId}
                                  </Text>
                                )}
                              </div>
                            </Stack>
                            {user.planType === 'vss' ? (
                              <OpenInNewOutlined color='primary' />
                            ) : (
                              <ChevronRight color='primary' />
                            )}
                          </StyledListItemButton>
                        ))}
                      </StyledList>
                    ))}
                  </Stack>
                ))}
                {personas.data?.stateSavers?.some(sS => 'error' in sS) && (
                  <StyledAlert severity='warning'>
                    Due to technical issues, access to State Saving Accounts
                    (IRA, 529 and Able) is temporarily unavailable. Please try
                    again after some time.
                  </StyledAlert>
                )}
              </StyledTabPanel>
            ) : undefined}
            {sponsors.length ? (
              <StyledTabPanel value={2}>
                {sponsors.length > 500 ? (
                  <Box
                    component={Card}
                    justifyContent='center'
                    width={isMobile ? dimensions.width - 20 : '660px'}>
                    <DataGrid
                      autoHeight
                      columnHeaderHeight={32}
                      columnVisibilityModel={{
                        externalSponsorPlanId: false
                      }}
                      columns={columns}
                      disableColumnMenu
                      disableRowSelectionOnClick
                      getRowId={row => row.sponsorId}
                      hideFooter={false}
                      hideFooterPagination={false}
                      localeText={{
                        footerTotalRows: 'Displaying'
                      }}
                      onCellKeyDown={(params, e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          onRowClick(params);
                          e.defaultMuiPrevented = true;
                        }
                      }}
                      onRowClick={onRowClick}
                      onSortModelChange={model => setSortModel(model)}
                      pageSizeOptions={[]}
                      rowHeight={40}
                      rowSelection={false}
                      rows={sponsors.flatMap(sponsor => sponsor.users)}
                      slotProps={{
                        toolbar: {
                          placeholder: 'Enter an ID or Name',
                          showQuickFilter: true,
                          sx: {
                            mb: 2
                          }
                        }
                      }}
                      slots={{
                        // @ts-expect-error - in new dataGrid it should be strongly typed, but not support yet
                        toolbar: GridToolbarQuickFilter
                      }}
                      sortModel={sortModel}
                      sx={{
                        '& .MuiDataGrid-row': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Box>
                ) : (
                  sponsors.map(sponsor => (
                    <StyledList
                      component='nav'
                      data-company={sponsor.company}
                      key={sponsor.company}>
                      {sponsor.users.map(user => (
                        <StyledListItemButton
                          data-firmcompanyname={user.firmCompanyName}
                          data-plantype={
                            planType[user.planType]?.nameForSponsor ??
                            user.planType
                          }
                          data-testid={user.sponsorId}
                          key={user.sponsorId}
                          onClick={() =>
                            onClick({
                              entityId: user.sponsorId,
                              entityType: user.type
                            })
                          }>
                          <Stack alignItems='center' direction='row'>
                            <StyledAvatar
                              customBgColor={
                                planType[user.planType]?.avatarBgColor
                              }>
                              {planType[user.planType]?.avatarIcon || (
                                <PersonOutlineOutlined color='grey100' />
                              )}
                            </StyledAvatar>
                            <div>
                              <Text color='grey50' mb={0} variant='e1'>
                                {user.company}
                              </Text>
                              <Text
                                color='grey100'
                                component='div'
                                mb={0}
                                variant='i1'>
                                <Stack
                                  direction={{ sm: 'row', xs: 'column' }}
                                  divider={
                                    <StyledDivider
                                      flexItem
                                      orientation='vertical'
                                    />
                                  }>
                                  {planType[user.planType] && (
                                    <b>
                                      {planType[user.planType]?.nameForSponsor}
                                    </b>
                                  )}
                                  {user.recordKeeperId === 4 &&
                                    user.firmCompanyName}
                                  {user.recordKeeperId === 2
                                    ? `Voya ID: ${
                                        user.externalSponsorPlanId ??
                                        PLACEHOLDER
                                      }`
                                    : `ID: ${user.sponsorPlanId ?? PLACEHOLDER}`}
                                </Stack>
                              </Text>
                            </div>
                          </Stack>
                          <ChevronRight color='primary' />
                        </StyledListItemButton>
                      ))}
                    </StyledList>
                  ))
                )}
              </StyledTabPanel>
            ) : undefined}
          </StyledTabs>
        </>
      )}
    </Stack>
  );
}

PersonasPage.displayName = 'PersonasPage';
