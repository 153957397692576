import { Box } from '@mui/material';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import { Link, Text } from '@vestwell-frontend/ui';

import { SignInLink } from '../components';
import { routes } from '../router/routes';

export function ResetPasswordExpiredPage() {
  useDocumentTitle('Reset Password');

  return (
    <>
      <Box mb={8} px={8}>
        <Text
          align='center'
          color='emphasize'
          data-testid='resetPasswordExpiredTitle'
          mb={8}
          variant='b2'>
          Reset Password
        </Text>
        <Text color='grey100' variant='f2'>
          This link has expired
        </Text>
        <Text color='grey100'>
          We care a lot about protecting your account. Disabling idle or used
          links helps us do just that.
        </Text>
        <Text color='grey100'>
          Please{' '}
          <Link data-testid='forgotPasswordLink' to={routes.FORGOT_PASSWORD}>
            request a new link to reset
          </Link>{' '}
          your password.
        </Text>
      </Box>
      <SignInLink />
    </>
  );
}

ResetPasswordExpiredPage.displayName = 'ResetPasswordExpiredPage';
