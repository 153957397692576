export function hexToRgba(hex: string, opacity = 1) {
  if (opacity === 1 || typeof opacity !== 'number') {
    return hex;
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const rgba = result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
        opacity
      ]
    : null;

  return rgba ? `rgba(${rgba.join(', ')})` : hex;
}
