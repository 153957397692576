import {
  GetWhiteLabelResDto,
  useGetWhiteLabel
} from '@portal-middleware/hooks';

import { createContext, FC, ReactNode, useContext } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';

type Config = GetWhiteLabelResDto;

type ConfigProviderProps = {
  children: ReactNode;
};

const ConfigContext = createContext<StoreApi<Config>>(null);

export const useConfig = () => {
  const store = useContext(ConfigContext);
  return useStore(store) as Config;
};

export const ConfigProvider: FC<ConfigProviderProps> = props => {
  const whitelabel = useGetWhiteLabel({
    query: {
      keepPreviousData: true,
      useErrorBoundary: true
    }
  });

  const configStore = createStore<Config>()(() => {
    return {
      ...whitelabel.data
    };
  });

  return whitelabel.isInitialLoading ? (
    <></>
  ) : (
    <ConfigContext.Provider value={configStore}>
      {props.children}
    </ConfigContext.Provider>
  );
};
