import { styled } from '@mui/material';

import { useField } from 'formik';
import { FC, useCallback, useContext } from 'react';

import { RadioGroup, RadioGroupProps } from '../RadioButton';
import { IsRequiredFieldContext } from './Form';

export type FormRadioGroupProps = Omit<
  RadioGroupProps,
  'value' | 'required'
> & {
  name: string;
  align?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
};

const StyledRadioGroup = styled(RadioGroup, {
  shouldForwardProp: prop => prop !== 'align'
})<FormRadioGroupProps>(props => ({
  ...(props.align && {
    '& .MuiFormGroup-root': {
      justifyContent: props.align
    }
  })
}));

export const FormRadioGroup: FC<FormRadioGroupProps> = props => {
  const isRequired = useContext(IsRequiredFieldContext)[props.name] ?? false;

  const [field, meta, helpers] = useField(props.name);

  const onBlur = useCallback(
    e => {
      helpers.setTouched(true);

      if (props.onBlur) {
        props.onBlur(e);
      }
    },
    [helpers.setTouched, props.onBlur]
  );

  const onChange = useCallback(
    value => {
      helpers.setValue(value);

      if (props.onChange) {
        props.onChange(value);
      }
    },
    [helpers.setValue, props.onChange]
  );

  return (
    <StyledRadioGroup
      {...props}
      error={!!meta.error && !!meta.touched}
      errorMessage={meta.touched ? meta.error : undefined}
      formField
      id={props.id || props.name}
      onBlur={onBlur}
      onChange={onChange}
      required={isRequired}
      value={field.value}
    />
  );
};

FormRadioGroup.displayName = 'FormRadioGroup';
