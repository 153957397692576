import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import { ReactNode, useMemo } from 'react';

type MessageFn = (message: ReactNode, options?: OptionsObject) => SnackbarKey;

export type UseNotifications = {
  close: (key?: SnackbarKey) => void;
  error: MessageFn;
  info: MessageFn;
  success: MessageFn;
  warning: MessageFn;
};

export function useNotifications(): UseNotifications {
  const snackbar = useSnackbar();

  return useMemo(
    () => ({
      close: key => snackbar.closeSnackbar(key),
      error: (message: ReactNode, options?: OptionsObject) =>
        snackbar.enqueueSnackbar(message, {
          ...options,
          variant: 'error'
        }),
      info: (message: ReactNode, options?: OptionsObject) =>
        snackbar.enqueueSnackbar(message, {
          ...options,
          variant: 'info'
        }),
      success: (message: ReactNode, options?: OptionsObject) =>
        snackbar.enqueueSnackbar(message, {
          ...options,
          variant: 'success'
        }),
      warning: (message: ReactNode, options?: OptionsObject) =>
        snackbar.enqueueSnackbar(message, {
          ...options,
          variant: 'warning'
        })
    }),
    [snackbar.closeSnackbar, snackbar.enqueueSnackbar]
  );
}
