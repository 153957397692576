import { Stack, StackProps, styled } from '@mui/material';

import { FC, ReactNode } from 'react';

export type ModalFooterProps = {
  children?: ReactNode;
  justifyBetween?: boolean;
  justifyCenter?: boolean;
  noBoxShadow?: boolean;
};

const StyledModalFooter = styled(Stack, {
  shouldForwardProp: prop =>
    !['justifyBetween', 'justifyCenter', 'noBoxShadow'].includes(
      prop.toString()
    )
})<
  {
    justifyBetween?: boolean;
    justifyCenter?: boolean;
    noBoxShadow?: boolean;
  } & StackProps
>(props => ({
  backgroundColor: props.theme.palette.white.main,
  borderBottomLeftRadius: '0.75rem',
  borderBottomRightRadius: '0.75rem',
  boxShadow: props.noBoxShadow
    ? 'none'
    : '0 0 #0000, 0 0 #0000, 1px -3px 5px #72727226',
  gap: props.justifyBetween || props.justifyCenter ? 0 : props.theme.spacing(4),
  justifyContent: props.justifyBetween
    ? 'space-between'
    : props.justifyCenter
      ? 'center'
      : 'flex-end',
  transform: 'translateY(-1px) scaleX(1) scaleY(1)',
  [props.theme.breakpoints.down('sm')]: {
    '& > button': {
      width: '100%'
    },
    flexDirection: 'column-reverse',
    gap: props.theme.spacing(2),
    justifyContent: 'center'
  }
}));

export const ModalFooter: FC<ModalFooterProps> = props => {
  return (
    <StyledModalFooter
      alignItems='center'
      bottom={0}
      data-component='modalFooter'
      direction='row'
      justifyBetween={props.justifyBetween}
      justifyCenter={props.justifyCenter}
      left={0}
      noBoxShadow={props.noBoxShadow}
      padding={2}
      right={0}
      role='presentation'>
      {props.children}
    </StyledModalFooter>
  );
};

ModalFooter.displayName = 'ModalFooter';
