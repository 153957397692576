import { Divider, Stack, styled, useMediaQuery } from '@mui/material';
import { useToggle } from '@vestwell-frontend/hooks';
import {
  Button,
  FormatPhoneNumber,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  Text
} from '@vestwell-frontend/ui';

import { FC, memo } from 'react';

import { useConfig } from '../contexts';

const StyledText = styled(Text)(({ theme }) => ({
  color: theme.palette.grey50.main,
  margin: theme.spacing(0, 4, 4, 4)
}));

export const HelpModal: FC = memo(() => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [isHelpModal, toggleIsHelpModal] = useToggle(false);

  const config = useConfig();

  return (
    !config.registration?.isState && (
      <>
        <Modal
          header='Need help with authentication?'
          isOpen={isHelpModal}
          onRequestClose={toggleIsHelpModal}>
          <ModalBody>
            <Stack
              direction={isMobile ? 'column' : 'row'}
              divider={<Divider flexItem orientation='vertical' />}
              justifyContent='space-between'>
              {!['jpmorgan'].includes(config.subdomain) && (
                <StyledText component='p' variant='f1'>
                  State savers with an IRA, 529, or Able account, please{' '}
                  <Link to='https://go.vestwell.com/vss/contact-us'>
                    contact support
                  </Link>{' '}
                  for assistance.
                </StyledText>
              )}
              <StyledText component='p' variant='f1'>
                For retirement savers, plan sponsors, or advisors, please call{' '}
                <Link
                  to={`tel:${config.support?.participant?.phone}`}
                  type='phone'>
                  <FormatPhoneNumber
                    value={config.support?.participant?.phone}
                  />
                </Link>
              </StyledText>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button autoFocus onClick={toggleIsHelpModal} variant='contained'>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Button onClick={toggleIsHelpModal} variant='inline'>
          Need help with authentication?
        </Button>
      </>
    )
  );
});

HelpModal.displayName = 'HelpModal';
