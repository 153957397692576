import { logout } from '@vestwell-frontend/helpers';

import { useEffect } from 'react';

export const useBackButtonBehavior = () => {
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);

    window.addEventListener('popstate', logout);

    return () => {
      window.removeEventListener('popstate', logout);
    };
  }, []);
};
