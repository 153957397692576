export const maskAccountNumber = (accountNumber: number | string) => {
  if (!accountNumber) return '';

  return accountNumber.toString().length >= 4
    ? `•••••${accountNumber.toString().substr(-4)}`
    : accountNumber;
};

export const maskEmail = email => {
  if (!email) return '';

  const [username, domain] = email.split('@');
  return username.substring(0, 3) + '••••••@' + domain;
};

export const maskPhoneNumber = phoneNumber => {
  if (!phoneNumber) return '';

  const prefix = '••• ••• ';
  return prefix + phoneNumber.slice(-4);
};

export const maskRoutingNumber = routingNumber => {
  if (!routingNumber) return '';

  return routingNumber.replace(/\d(?=\d{4})/g, '•');
};
