import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Alert, Grid2 } from '@mui/material';
import { useGetQrCode, usePostMfaSetup } from '@sentinel/hooks';
import {
  Button,
  Link,
  ModalBody,
  ModalFooter,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import { useMount } from 'react-use';

import { MfaSetupState } from '../config/mfaSetup';
import { MfaQrCode } from './MfaQrCode';

export const MfaSetupApp: FC = () => {
  const ctx = useWizard<MfaSetupState>();

  const setup = usePostMfaSetup({
    mutation: {
      onSuccess: res => {
        console.log(res.code);
      }
    }
  });

  const qrCode = useGetQrCode({
    query: {
      enabled: setup.isSuccess,
      refetchOnMount: 'always',
      suspense: false
    }
  });

  const onContinue = useCallback(() => {
    ctx.setState({ methodSelected: true });
  }, []);

  useMount(async () => {
    await setup.mutateAsync({
      data: { type: 'app' }
    });
  });

  const isLoading = setup.isLoading || qrCode.isInitialLoading;

  return (
    <>
      <ModalBody>
        <Grid2 alignItems='center' container direction='column' spacing={4}>
          <Grid2
            alignItems='center'
            display='flex'
            flexDirection='column'
            size={{
              lg: 10,
              xs: 12
            }}>
            <Text align='center'>
              Download an authenticator app such as{' '}
              <Link
                data-testid='googleAuthenticatorLink'
                external
                to='https://support.google.com/accounts/answer/1066447'>
                Google Authenticator
              </Link>
              , or{' '}
              <Link
                data-testid='microsoftAuthenticatorLink'
                external
                to='https://support.microsoft.com/en-au/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a'>
                Microsoft Authenticator
              </Link>
              . Once downloaded, scan the following barcode from within the app.
            </Text>
          </Grid2>
          <Grid2
            alignItems='center'
            display='flex'
            flexDirection='column'
            size={{
              lg: 9,
              xs: 12
            }}>
            <MfaQrCode
              isLoading={qrCode.isInitialLoading}
              qrCode={qrCode.data?.qrCode}
              secret={qrCode.data?.secret}
            />
          </Grid2>
          {(setup.error || qrCode.error) && (
            <Grid2 alignItems='center' display='flex' flexDirection='column'>
              <Alert
                classes={{ root: 'flex-shrink inline-flex' }}
                severity='error'
                variant='standard'>
                {setup.error?.message || qrCode.error?.message}
              </Alert>
            </Grid2>
          )}
        </Grid2>
      </ModalBody>
      <ModalFooter>
        <Button
          autoFocus
          disabled={isLoading || !!setup.error || !!qrCode.error}
          endIcon={<KeyboardArrowRightOutlined fontSize='small' />}
          onClick={onContinue}>
          Next: Verify
        </Button>
      </ModalFooter>
    </>
  );
};

MfaSetupApp.displayName = 'MfaSetupApp';
