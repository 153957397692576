import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { SemVer } from 'semver';

export const getMobileHostname = async () => {
  const capgoVersion = await SecureStoragePlugin.get({
    key: 'CAPGO_BUNDLE'
  });
  const localhostCutoffVersion = new SemVer('3.0.0');
  if (
    capgoVersion.value &&
    new SemVer(capgoVersion.value).compare(localhostCutoffVersion) < 0
  ) {
    return `localhost`;
  } else {
    return `mobile.vestwell`;
  }
};
