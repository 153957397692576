import { Box } from '@mui/material';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import { Link, Text } from '@vestwell-frontend/ui';

import { SignInLink } from '../components';
import { routes } from '../router/routes';

export function AccountLockedPage() {
  useDocumentTitle('Account Locked');

  return (
    <>
      <Box mb={8} px={8}>
        <Text
          align='center'
          color='emphasize'
          data-testid='accountLockedTitle'
          mb={8}
          variant='b2'>
          Account Locked
        </Text>
        <Text color='grey100' variant='f2'>
          You have attempted too many invalid sign-in attempts. For your
          security, your account has been locked.
        </Text>
        <Text color='grey100' variant='f2'>
          In order to regain access to your account, please{' '}
          <Link data-testid='forgotPasswordLink' to={routes.FORGOT_PASSWORD}>
            reset your password.
          </Link>
        </Text>
      </Box>
      <SignInLink />
    </>
  );
}

AccountLockedPage.displayName = 'AccountLockedPage';
