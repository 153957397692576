import { TokenResDto } from '@sentinel/hooks';
import {
  broadcastToApp,
  cleanUpStaleCookies,
  isEmbeddedBrowser,
  isNativeMobile,
  logout
} from '@vestwell-frontend/helpers';
import { useSecureStorage } from '@vestwell-frontend/hooks';

import axios from 'axios';
import Cookies from 'js-cookie';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { useLocation } from 'react-use';
import { createStore, StoreApi, useStore } from 'zustand';

type Auth = {
  isNative: boolean;
  token: string;
  setToken: (token: TokenResDto | string) => void;
  updateToken: () => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<StoreApi<Auth>>(null);

export const useAuth = () => {
  const store = useContext(AuthContext);
  return useStore(store) as Auth;
};

export const AuthProvider: FC<AuthProviderProps> = props => {
  const host = useLocation().host.split('.').slice(-2).join('.');
  const [mobileToken, setMobileToken] = useSecureStorage('token');
  const [mobileTokenExpiration, setMobileTokenExpiration] =
    useSecureStorage('tokenExpiration');
  const [isEmbedded, setIsEmbedded] = useState(false);
  const [isNativeApp, setIsNativeApp] = useState(false);
  const [token, setAuthToken] = useState<string | null>();
  const [tokenExpiration, setAuthTokenExpiration] = useState<string | null>(
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const embeddedResult = await isEmbeddedBrowser();
        const isNative = await isNativeMobile();

        setIsEmbedded(embeddedResult);
        setIsNativeApp(isNative);
      } catch (error) {
        console.error('Error checking environment:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isNativeApp) {
      const tokenData = JSON.parse(mobileToken);
      const expiration = JSON.parse(mobileTokenExpiration);

      if (
        tokenData?.access_token &&
        tokenData?.token_type &&
        expiration?.expiration
      ) {
        setAuthToken(`${tokenData.token_type} ${tokenData.access_token}`);
        setAuthTokenExpiration(expiration.expiration);
      }
    } else {
      setAuthToken(Cookies.get('token'));
      setAuthTokenExpiration(Cookies.get('tokenExpiration'));
    }
  }, [mobileToken, isNativeApp, mobileTokenExpiration]);

  const authConfigStore = createStore<Auth>()(() => ({
    isNative: isNativeApp,
    setToken: token => {
      if (!token) {
        cleanUpStaleCookies();
        return;
      }

      // Set token expiration to 13 minutes (to be safe, real expiration is 15 minutes)
      const expirationTime = new Date(
        new Date().getTime() + 13 * 60 * 1000
      ).toISOString();

      if (
        typeof token !== 'string' &&
        token?.token_type &&
        token?.access_token
      ) {
        if (isNativeApp) {
          setMobileToken(JSON.stringify(token));
          setMobileTokenExpiration(
            JSON.stringify({ expiration: expirationTime })
          );
        }

        if (isEmbedded) {
          broadcastToApp('JWT', { ...token, expirationTime });
        }
      }

      const accessToken =
        typeof token !== 'string' && token?.token_type && token?.access_token
          ? `${token.token_type} ${token.access_token}`
          : // password case
            typeof token === 'string'
            ? token
            : null;

      const refreshToken =
        typeof token !== 'string' ? token?.refresh_token : null;

      Cookies.set('token', accessToken, {
        domain: `.${host}`,
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
        path: '/'
      });

      Cookies.set('tokenExpiration', expirationTime, {
        domain: `.${host}`,
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
        path: '/'
      });
      Cookies.set('refreshToken', refreshToken, {
        domain: `.${host}`,
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
        path: '/'
      });
      axios.defaults.headers.common['Authorization'] = accessToken;
    },
    token,
    tokenExpiration,
    updateToken: async () => {
      try {
        let refreshToken;
        if (isNativeApp) {
          const tokenData = JSON.parse(mobileToken);
          refreshToken = tokenData.refresh_token;
        } else {
          refreshToken = Cookies.get('refreshToken');
        }

        const result = await axios.post('/auth/api/v1/oidc/token', {
          grant_type: 'refresh_token',
          refresh_token: refreshToken
        });

        authConfigStore.getState().setToken(result.data);
      } catch (error) {
        console.error('Failed to refresh token', error);
        await logout(true);
      }
    }
  }));

  useLayoutEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
    }
  }, [token]);

  useEffect(() => {
    if (!token && !tokenExpiration) {
      // If there's no token, don't start the interval
      return;
    }

    const checkTokenExpiration = () => {
      const expirationTime = new Date(tokenExpiration || null).valueOf();
      const currentTime = new Date().valueOf();

      if (expirationTime < currentTime) {
        authConfigStore.getState().updateToken();
      }
    };

    //checks on page refresh
    checkTokenExpiration();

    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 1000 * 60); // Check every minute

    return () => clearInterval(interval);
  }, [token, tokenExpiration, isNativeApp]);

  return (
    <AuthContext.Provider value={authConfigStore}>
      {props.children}
    </AuthContext.Provider>
  );
};
