import {
  Check,
  Close,
  InfoOutlined,
  WarningAmberOutlined
} from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';

import {
  MaterialDesignContent,
  SnackbarProvider,
  SnackbarProviderProps
} from 'notistack';
import { FC, ReactNode, useRef } from 'react';

export type NotificationsProviderProps = SnackbarProviderProps & {
  children: ReactNode;
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&.notistack-MuiContent': {
      fontSize: theme.spacing(4)
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.main
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.palette.info.main
    },
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.warning.main
    }
  })
);

export const NotificationsProvider: FC<NotificationsProviderProps> = ({
  anchorOrigin = {
    horizontal: 'center',
    vertical: 'bottom'
  },
  autoHideDuration = 3000,
  children,
  maxSnack = 6,
  preventDuplicate = true,
  ...props
}) => {
  const $snackbar = useRef(null);

  const onDismiss = e => {
    $snackbar.current.closeSnackbar(e.target.dataset.key);
  };

  return (
    <SnackbarProvider
      Components={{
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent
      }}
      action={key => (
        <IconButton
          aria-label='Close Notification'
          color='white'
          data-key={key}
          onClick={onDismiss}
          size='small'
          sx={{ marginRight: 4 }}>
          <Close />
        </IconButton>
      )}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      iconVariant={{
        error: (
          <InfoOutlined
            color='white'
            fontSize='small'
            sx={{ marginRight: 2 }}
          />
        ),
        info: (
          <InfoOutlined
            color='white'
            fontSize='small'
            sx={{ marginRight: 2 }}
          />
        ),
        success: (
          <Check color='white' fontSize='small' sx={{ marginRight: 2 }} />
        ),
        warning: (
          <WarningAmberOutlined
            color='white'
            fontSize='small'
            sx={{ marginRight: 2 }}
          />
        )
      }}
      maxSnack={maxSnack}
      preventDuplicate={preventDuplicate}
      {...props}
      ref={$snackbar}>
      {children}
    </SnackbarProvider>
  );
};
