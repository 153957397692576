import { styled } from '@mui/material';
import type { HttpErrorType } from '@vestwell-frontend/hooks';

import { FC } from 'react';

import { Error, ErrorProps } from './Error';
import { Error404Icon, Error500Icon, ExceptionIcon } from './icon';

export const ERRORS = {
  401: {
    message: 'Redirecting to login…',
    title: 'You have been logged out'
  },
  404: {
    message:
      'The page you were looking for does not seem to exist. We apologize for any inconvenience. Rest assured we are actively looking into where it went.',
    title: 'Page Not Found'
  },
  500: {
    message:
      'We recommend refreshing the page or going back and trying again. We apologize for any inconvenience. Rest assured we are actively looking into what happened.',
    title: 'Whoops… something’s gone wrong.'
  }
};

export const GENERIC_ERROR = {
  message:
    'If the problem persists, please reach out and tell us a bit about what happened.',
  title: 'Something’s gone wrong!'
};

export type StatusCodeErrorProps = Partial<
  HttpErrorType &
    ErrorProps & {
      /** Styles to merge with container */
      className?: string;
    }
>;

const StyledError404Icon = styled(Error404Icon)(({ theme }) => ({
  height: theme.spacing(32),
  width: theme.spacing(32)
}));

const StyledExceptionIcon = styled(ExceptionIcon)(({ theme }) => ({
  color: theme.palette.dijon.main,
  height: theme.spacing(32),
  marginBottom: theme.spacing(4),
  width: theme.spacing(32)
}));

const StyledError500Icon = styled(Error500Icon)(({ theme }) => ({
  height: theme.spacing(32),
  width: theme.spacing(32)
}));

export const StatusCodeError: FC<StatusCodeErrorProps> = ({
  status,
  ...props
}) => {
  const error = ERRORS[status] || GENERIC_ERROR;

  const errorStatus = status?.toString();

  const icon =
    errorStatus === '404'
      ? StyledError404Icon
      : errorStatus === '500'
        ? StyledError500Icon
        : StyledExceptionIcon;

  return (
    <Error
      {...props}
      details={error.message}
      icon={icon}
      name='StatusCodeError'
      showRefreshButton={errorStatus === '500'}
      title={error.title}
    />
  );
};

StatusCodeError.displayName = 'StatusCodeError';
