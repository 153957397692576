import { useNotifications } from '@vestwell-frontend/hooks';
import { Modal, WizardProvider, WizardStep } from '@vestwell-frontend/ui';

import { FC, useEffect, useMemo, useState } from 'react';

import { mfaSetup, MfaSetupState } from '../config/mfaSetup';

export type MfaEditModalProps = {
  isOpen: boolean;
  method?: MfaSetupState['method'];
  mfaEntryId: number;
  onClose: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
  value?: string;
};

export const MfaEditModal: FC<MfaEditModalProps> = props => {
  const notify = useNotifications();

  const [state, setState] = useState<MfaSetupState>(null);
  const [step, setStep] = useState<WizardStep>(null);

  const initialState = useMemo(() => {
    return {
      ...mfaSetup.initialState,
      method: props.method,
      mfaEntryId: props.mfaEntryId,
      ...('phone' === props.method ? { value: props.value } : {})
    };
  }, [props.method, props.value]);

  useEffect(() => {
    if (state?.methodVerified) {
      notify.success(
        `${state.method === 'app' ? 'App based' : 'Phone based'} 2FA updated`
      );

      props.onClose();

      setStep(null);
      setState(null);

      props.onSuccess();
    }
  }, [state, props.onSuccess]);

  const Component = step?.Component;

  return (
    <WizardProvider<MfaSetupState>
      initialState={initialState}
      key={props.isOpen ? '1' : '0'}
      name='Mfa Edit'
      onStateChange={setState}
      onStepChange={setStep}
      steps={mfaSetup.steps}>
      {step && (
        <Modal closeButton isOpen={props.isOpen} onRequestClose={props.onClose}>
          {Component && <Component isMfaSetup onClose={props.onClose} />}
        </Modal>
      )}
    </WizardProvider>
  );
};

MfaEditModal.displayName = 'MfaEditModal';
