import { createSvgIcon, SvgIconProps } from '@mui/material';

import { FC } from 'react';

export const ExceptionIcon: FC<SvgIconProps> = createSvgIcon(
  <svg viewBox='0 0 127 128'>
    <path
      d='M9.61352 18.6494C8.31439 19.9511 8.31439 21.7737 9.61352 23.0755C10.9127 24.3772 12.7315 24.3772 14.0306 23.0755C36.1159 0.944975 71.9721 0.944975 94.0574 23.0755C102.372 31.4069 107.049 42.8627 107.049 54.5788C107.049 66.5553 102.372 77.7507 94.0574 86.0822C87.3019 92.8515 78.7276 96.4966 69.3738 96.4966C60.02 96.4966 51.1859 92.8515 44.6902 86.0822C39.4937 80.875 36.6356 74.1057 36.6356 66.5553C36.6356 59.2653 39.4937 52.2356 44.6902 47.0284C45.9893 45.7266 45.9893 43.9041 44.6902 42.6023C43.3911 41.3005 41.5723 41.3005 40.2731 42.6023C27.0219 55.8806 27.0219 77.23 40.2731 90.2479C48.068 98.0587 58.461 102.485 69.6336 102.485C80.8062 102.485 90.9394 98.0587 98.9941 90.2479C108.608 80.6147 113.804 67.8571 113.804 54.0581C113.804 40.2591 108.608 27.5015 98.9941 17.8683C74.0507 -6.08471 34.0373 -6.08471 9.61352 18.6494z'
      fill='currentColor'
      role='presentation'
    />
    <path
      d='M71.7123 19.1701C71.7123 17.3476 70.1533 15.7855 68.3346 15.7855C66.5158 15.7855 64.9568 17.3476 64.9568 19.1701C64.9568 20.9926 66.5158 22.5548 68.3346 22.5548C70.1533 22.5548 71.7123 20.9926 71.7123 19.1701z'
      fill='currentColor'
      role='presentation'
    />
    <path
      d='M7.01533 27.7618C7.01533 25.9393 5.45637 24.3772 3.63758 24.3772C1.81879 24.3772 0 25.9393 0 27.7618C0 29.8447 1.55896 31.4069 3.37775 31.4069C5.19654 31.4069 7.01533 29.8447 7.01533 27.7618z'
      fill='currentColor'
      role='presentation'
    />
    <path
      d='M7.01538 36.0933C8.83417 36.0933 10.3931 37.6555 10.3931 39.478C10.3931 41.5608 8.83417 43.123 7.01538 42.8626C5.19659 42.8626 3.63763 41.3005 3.63763 39.478C3.63763 37.6555 5.19659 36.0933 7.01538 36.0933z'
      fill='currentColor'
      role='presentation'
    />
    <path
      d='M119.52 88.6859C117.702 88.6859 116.143 90.248 116.143 92.0705C116.143 93.893 117.702 95.4552 119.52 95.4552C121.339 95.4552 122.898 93.893 122.898 92.0705C122.898 90.248 121.339 88.6859 119.52 88.6859z'
      fill='currentColor'
      role='presentation'
    />
    <path
      d='M120.56 63.6914C120.56 61.8689 122.119 60.3067 123.938 60.3067C125.756 60.3067 127.315 61.8689 127.315 63.6914C127.315 65.5139 125.756 67.0761 123.938 67.0761C122.119 67.0761 120.56 65.5139 120.56 63.6914z'
      fill='currentColor'
      role='presentation'
    />
    <path
      d='M49.3672 106.651C49.3672 104.828 47.8082 103.266 45.9895 103.266C44.1707 103.266 42.6117 104.828 42.6117 106.651C42.6117 108.473 44.1707 110.035 45.9895 110.035C47.8082 110.035 49.3672 108.473 49.3672 106.651z'
      fill='currentColor'
      role='presentation'
    />
    <path
      d='M68.0746 121.231C83.1446 121.231 97.4351 115.242 108.088 104.568C109.127 103.266 110.686 103.787 111.985 105.088C113.285 106.39 113.285 108.213 111.985 109.515C100.033 121.491 84.4437 128 67.555 128C50.6662 128 35.0766 121.491 23.1245 109.515C13.5109 99.8813 8.31438 87.1237 8.31438 73.3247C8.31438 59.5257 13.5109 46.7681 23.1245 37.1349C31.1792 29.3241 41.3124 24.898 52.485 24.898C63.6576 24.898 74.0507 29.3241 81.8455 37.1349C95.0967 50.1528 95.0967 71.5022 81.8455 84.7805C80.5463 86.0823 78.7275 86.0823 77.4284 84.7805C76.1293 83.4787 76.1293 81.6562 77.4284 80.3544C88.0813 69.6797 88.0813 52.2357 77.4284 41.561C70.9327 34.7916 62.0986 31.1466 52.7448 31.1466C43.391 31.1466 34.8168 34.7916 28.0612 41.561C19.7468 49.8924 15.0699 61.0878 15.0699 73.0643C15.0699 84.7805 19.7468 96.2362 28.0612 104.568C38.7142 115.242 53.0047 121.231 68.0746 121.231z'
      fill='currentColor'
      role='presentation'
    />
    <path
      clipRule='evenodd'
      d='M42.8715 63.1707C42.8715 52.7564 51.186 44.4249 61.579 44.4249C71.7123 44.4249 80.2866 52.7564 80.2866 63.1707C80.2866 73.5851 71.9721 81.9165 61.579 81.9165C51.186 81.9165 42.8715 73.5851 42.8715 63.1707zM49.1073 63.4311C49.1073 70.2004 54.8235 75.9283 61.579 75.9283C68.3346 75.9283 74.0508 70.2004 74.0508 63.4311C74.0508 56.6618 68.3346 50.9339 61.579 50.9339C54.8235 50.9339 49.1073 56.6618 49.1073 63.4311z'
      fill='currentColor'
      fillRule='evenodd'
      role='presentation'
    />
  </svg>,
  'ExceptionIcon'
);

ExceptionIcon.displayName = 'ExceptionIcon';
