import { logout } from '@vestwell-frontend/helpers';
import { Button, Text } from '@vestwell-frontend/ui';

import { FC, memo } from 'react';

import { routes } from '../router/routes';

export type SignInButtonProps = {
  forceLogout?: boolean;
  mb?: number;
};

export const SignInLink: FC<SignInButtonProps> = memo(
  (props: SignInButtonProps) => {
    return (
      <Text align='center' mb={props.mb}>
        <Button
          data-testid='signInLink'
          onClick={props.forceLogout ? logout : undefined}
          to={props.forceLogout ? undefined : routes.SIGN_IN}
          variant='inline'>
          Return to login
        </Button>
      </Text>
    );
  }
);

SignInLink.displayName = 'SignInLink';
