import { useGetWhiteLabel } from '@portal-middleware/hooks';

import { FC, useEffect } from 'react';

export const Favicon: FC = () => {
  const whitelabel = useGetWhiteLabel();

  useEffect(() => {
    if (!whitelabel.data?.metadata?.favicon) {
      return;
    }

    let link: HTMLLinkElement = document.querySelector('link#favicon');

    if (!link) {
      link = document.createElement('link');
      link.id = 'favicon';
      link.type = 'image/x-icon';
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    link.href = whitelabel.data?.metadata?.favicon;
  }, [whitelabel.data]);

  useEffect(() => {
    if (!['jpmorgan'].includes(whitelabel.data?.subdomain)) {
      return;
    }

    let link: HTMLLinkElement = document.querySelector('link#apple-touch-icon');

    if (!link) {
      link = document.createElement('link');
      link.id = 'apple-touch-icon';
      link.rel = 'apple-touch-icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    link.href = `/images/touch-icons/${whitelabel.data?.subdomain}-180x180.png`;
  }, [whitelabel.data]);

  return null;
};

Favicon.displayName = 'Favicon';
