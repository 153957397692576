import { WizardStep } from '@vestwell-frontend/ui';

import { ChangeLoginEmail } from '../components/ChangeLoginEmail';
import { ChangeLoginEmailVerifyCode } from '../components/ChangeLoginEmailVerifyCode';
import { VerifyPassword } from '../components/VerifyPassword';

export type ChangeLoginEmailState = {
  emailVerified?: boolean;
  codeVerified?: boolean;
  passwordVerified?: boolean;
  newLoginEmail?: string;
};

export const changeLoginEmail = {
  initialState: {
    codeVerified: false,
    emailVerified: false,
    newLoginEmail: '',
    passwordVerified: false
  },
  steps: [
    {
      Component: VerifyPassword,
      title: 'Please Verify Your Password',
      when: state => !state.passwordVerified
    },
    {
      Component: ChangeLoginEmail,
      title: 'Enter New Login Email',
      when: state => state.passwordVerified
    },
    {
      Component: ChangeLoginEmailVerifyCode,
      title: 'Verify Security Code',
      when: state => state.emailVerified
    }
  ] as WizardStep<ChangeLoginEmailState>[]
};
