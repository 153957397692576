import { Alert, CircularProgress, styled } from '@mui/material';

import { FC, HTMLAttributes, ReactNode } from 'react';

export type ModalBodyProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  children: ReactNode;
  error?: string | ReactNode;
  isLoading?: boolean;
  isRounded?: boolean;
};

const StyledModalBody = styled('div', {
  shouldForwardProp: prop => prop !== 'isRounded'
})<{
  isRounded?: boolean;
}>(props => ({
  /** WCAG 1.4.10 */
  '@media (max-height: 500px)': {
    overflow: 'unset'
  },
  borderRadius: props.isRounded ? '0.75rem' : undefined,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden'
}));

const StyledModalBodyContents = styled('div', {
  shouldForwardProp: prop => prop !== 'isLoading'
})<{
  isLoading?: boolean;
}>(({ theme, isLoading }) => ({
  /** WCAG 1.4.10 */
  '@media (max-height: 500px)': {
    overflowY: 'unset'
  },
  overflowY: 'auto',
  padding: theme.spacing(10, 10),
  webkitOverflowScrolling: 'touch',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(10, 6)
  },
  ...(isLoading && {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  })
}));

export const ModalBody: FC<ModalBodyProps> = ({ isRounded, ...props }) => {
  return (
    <StyledModalBody isRounded={isRounded}>
      <StyledModalBodyContents
        data-component='modalBody'
        isLoading={props.isLoading}
        role='presentation'
        {...props}>
        {props.isLoading && <CircularProgress data-component='modalLoader' />}
        {typeof props.error === 'string' && (
          <Alert
            dangerouslySetInnerHTML={{ __html: props.error }}
            data-component='modalError'
            severity='error'
          />
        )}
        {props.error && typeof props.error !== 'string' && (
          <Alert data-component='modalError' severity='error'>
            {props.error}
          </Alert>
        )}
        {!props.isLoading && props.children}
      </StyledModalBodyContents>
    </StyledModalBody>
  );
};

ModalBody.displayName = 'ModalBody';
