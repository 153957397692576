import { Button, ButtonProps } from '@vestwell-frontend/ui';

import { differenceInSeconds } from 'date-fns';
import { useField } from 'formik';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { useInterval, useUnmount } from 'react-use';
import { useLocalStorage } from 'usehooks-ts';

import { MfaSetupState } from '../config/mfaSetup';

export type ResendOtpButtonProps = ButtonProps & {
  /** name of the input to set the otp value for */
  inputName: string;
  mfaEntryId?: MfaSetupState['mfaEntryId'];
  subType?: MfaSetupState['subType'];
};

export const ResendOtpButton = forwardRef<
  HTMLButtonElement,
  ResendOtpButtonProps
>(({ inputName, mfaEntryId, subType, ...props }, ref) => {
  const initSeconds = 30;

  const $abort = useRef<AbortController>(null);

  const [, , helpers] = useField(inputName);

  const [codeLastSentTime, setCodeLastSentTime] = useLocalStorage(
    'codeLastSentTime',
    {}
  );

  const [seconds, setSeconds] = useState(
    initSeconds -
      differenceInSeconds(
        Date.now(),
        codeLastSentTime?.[mfaEntryId ?? inputName]
      )
  );

  const onClick = useCallback(
    async e => {
      setCodeLastSentTime(prevState => ({
        ...prevState,
        [mfaEntryId ?? inputName]: Date.now()
      }));

      await props.onClick(e);

      setSeconds(initSeconds);

      /** if it's an android device, listen for the sms credentials */
      //@ts-expect-error: Move it out from zendesk
      if (window?.OTPCredentials) {
        if ($abort.current) {
          $abort.current.abort();
        }

        $abort.current = new AbortController();

        const sms = await (navigator.credentials
          .get({
            //@ts-expect-error: Move it out from zendesk
            otp: {
              signal: $abort.current.signal,
              transport: ['sms']
            }
          })
          .catch(() =>
            Promise.resolve({
              code: null
            })
          ) as Promise<{ code?: string }>);

        $abort.current = null;

        if (sms?.code) {
          helpers.setValue(sms.code);
        }
      }
    },
    [initSeconds, props.onClick]
  );

  const isDisabled = useMemo(
    () => seconds > 0 && seconds <= initSeconds,
    [seconds, initSeconds]
  );

  useInterval(
    () => {
      setSeconds(prev => prev - 1);
    },
    seconds !== 0 ? 1000 : null
  );

  useUnmount(() => {
    if ($abort.current) {
      $abort.current.abort();
      $abort.current = null;
    }
  });

  return (
    <Button
      {...props}
      data-testid='resend'
      disabled={isDisabled || props.disabled}
      onClick={onClick}
      ref={ref}>
      {subType === 'voice' ? 'Call again?' : 'Resend Code'}
      {seconds > 0 && seconds <= initSeconds && ` (${seconds}s)`}
    </Button>
  );
});

ResendOtpButton.displayName = 'ResendOtpButton';
