import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Alert, Grid2, styled } from '@mui/material';
import { usePostMfaSetup } from '@sentinel/hooks';
import { validatePhone } from '@vestwell-frontend/helpers';
import {
  Form,
  FormField,
  FormRadioGroup,
  FormSaveButton,
  ModalBody,
  ModalFooter,
  RadioButton,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import * as yup from 'yup';

import { MfaSetupState } from '../config/mfaSetup';

const schema = yup.object().shape({
  phone: validatePhone('Must be a valid US phone number'),
  type: yup.string()
});

const StyledFormField = styled(FormField)({
  width: '100%'
});

export const MfaSetupPhone: FC = () => {
  const ctx = useWizard<MfaSetupState>();

  const setup = usePostMfaSetup({
    mutation: {
      onSuccess: res => {
        console.log(res.code);
        ctx.setState({
          methodSelected: true,
          //@ts-expect-error: waiting for hooks
          subType: res.entrySubType,
          value: res.phoneNumber
        });
      }
    }
  });

  const onSubmit = useCallback(async values => {
    await setup.mutateAsync({
      data: {
        mfaEntryId: ctx.state.mfaEntryId,
        subType: values.type,
        type: 'phone',
        value: values.phone
      }
    });
  }, []);

  return (
    <Form
      className='contents'
      initialValues={{
        phone: ctx.state.value || '',
        type: ctx.state.subType ?? 'text'
      }}
      onChange={setup.reset}
      onSubmit={onSubmit}
      validateOnMount={false}
      validationSchema={schema}>
      <ModalBody>
        <Grid2 container direction='column' spacing={4}>
          <Grid2
            size={{
              lg: 10,
              md: 12
            }}>
            <Text mb={0}>
              We will provide you a 6-digit security code via the method you
              prefer.
            </Text>
          </Grid2>
          <Grid2
            display='flex'
            flexDirection='column'
            flexGrow={1}
            size={{
              lg: 6,
              md: 9,
              xs: 12
            }}>
            <StyledFormField
              autoFocus
              format='phoneUs'
              fullWidth
              label='Phone Number'
              name='phone'
            />
          </Grid2>
          <Grid2
            display='flex'
            flexDirection='column'
            flexGrow={1}
            size={{
              lg: 6,
              md: 9,
              xs: 12
            }}>
            <FormRadioGroup
              label='Verification Method'
              name='type'
              variant='standard'>
              <RadioButton label='Via text message' value='text' />
              <RadioButton label='Via voice call' value='voice' />
            </FormRadioGroup>
          </Grid2>
          {setup.error && (
            <Grid2>
              <Alert
                classes={{ root: 'flex-shrink inline-flex' }}
                severity='error'
                variant='standard'>
                {setup.error?.message}
              </Alert>
            </Grid2>
          )}
        </Grid2>
      </ModalBody>
      <ModalFooter>
        <FormSaveButton
          disabled={!!setup.error}
          endIcon={<KeyboardArrowRightOutlined fontSize='small' />}>
          Next: Verify
        </FormSaveButton>
      </ModalFooter>
    </Form>
  );
};

MfaSetupPhone.displayName = 'MfaSetupPhone';
