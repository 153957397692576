import { logout } from '@vestwell-frontend/helpers';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import { Loader } from '@vestwell-frontend/ui';

import { useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

export function LogoutPage() {
  useDocumentTitle('Signing out');

  const [params] = useSearchParams();

  useMount(async () => {
    await logout(params.get('timeout') === 'true');
  });

  return <Loader />;
}

LogoutPage.displayName = 'LogoutPage';
