import { styled } from '@mui/material/styles';
import { SuspenseImage } from '@vestwell-frontend/ui';

import { FC } from 'react';

import { useConfig } from '../contexts';

const StyledBanner = styled(SuspenseImage)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    alignSelf: 'center',
    borderBottomLeftRadius: theme.spacing(2.5),
    borderBottomRightRadius: theme.spacing(2.5),
    maxWidth: theme.spacing(360),
    position: 'fixed',
    top: 0,
    zIndex: -1
  }
}));

export const Banner: FC = () => {
  const config = useConfig();

  // todo:: update db entries path & extension
  return (
    !!config.login?.banner && (
      <StyledBanner
        data-component='banner'
        src={config.login.banner
          .replace('/clients/login', '')
          .replace('.png', '.webp')}
      />
    )
  );
};

Banner.displayName = 'Banner';
