import { styled } from '@mui/material';
import {
  Button,
  Modal,
  WizardProvider,
  WizardStep
} from '@vestwell-frontend/ui';

import { FC, useEffect, useState } from 'react';
import { useToggle } from 'react-use';

import {
  changeLoginEmail,
  ChangeLoginEmailState
} from '../config/changeLoginEmail';

type ChangeLoginEmailModalProps = {
  onSuccess: () => Promise<any>;
};

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

export const ChangeLoginEmailModal: FC<ChangeLoginEmailModalProps> = props => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [state, setState] = useState<ChangeLoginEmailState>(null);
  const [step, setStep] = useState<WizardStep>(null);

  useEffect(() => {
    if (state?.codeVerified) {
      setStep(null);
      setState(null);

      props.onSuccess();
    }
  }, [state]);

  const Component = step?.Component;

  return (
    <>
      <StyledButton
        aria-haspopup='dialog'
        aria-label='Change Login Email'
        data-testid='changeLoginEmailButton'
        onClick={toggleIsOpen}
        variant='inline'>
        Change Login Email
      </StyledButton>
      <WizardProvider<ChangeLoginEmailState>
        initialState={changeLoginEmail.initialState}
        key={isOpen ? '1' : '0'}
        name='Change Login Email'
        onStateChange={setState}
        onStepChange={setStep}
        steps={changeLoginEmail.steps}>
        {step && (
          <Modal
            header={step.title}
            isOpen={isOpen}
            onRequestClose={toggleIsOpen}>
            {Component && (
              <Component isMfaSetup={false} onClose={toggleIsOpen} />
            )}
          </Modal>
        )}
      </WizardProvider>
    </>
  );
};

ChangeLoginEmailModal.displayName = 'ChangeLoginEmailModal';
