import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

import { isNativeMobile } from './isNativeMobile';

//https://cordova.apache.org/docs/en/10.x/reference/cordova-plugin-inappbrowser/
export type InAppMessageEvent = {
  type: 'loadstart' | 'loadstop' | 'loaderror' | 'message' | 'exit';
  url: string;
  code?: string;
  message?: string;
  data?: any;
};
export type OnMessageHandler = (
  event: InAppMessageEvent,
  inAppBrowser: InAppBrowser
) => Promise<void>;
export type OnExitHandler = (event: InAppMessageEvent) => void;
export type InAppBrowser = { open: () => void; close: () => void };

export const handleMessage = async (
  event: InAppMessageEvent,
  inAppBrowser: InAppBrowser
) => {
  const { channel, content } = event.data;
  switch (channel) {
    case 'JWT':
      console.log('Received JWT:', content);
      await SecureStoragePlugin.set({
        key: 'token',
        value: JSON.stringify(content)
      });
      break;
    case 'CLOSE':
      console.log('Closing in-app browser');
      inAppBrowser.close();
      break;
    default:
      console.error('Unknown message type:', channel, content);
  }
};

export const EmbeddedBrowser = async (
  path,
  onMessage?: OnMessageHandler,
  onExit?: OnExitHandler
) => {
  const isNative = await isNativeMobile();
  if (!isNative) {
    return;
  }
  try {
    // This dependency exists in the global scope when the app is running in the mobile app env.
    // It's included by the mobile-app repo.
    //@ts-ignore
    const browser = window.cordova.InAppBrowser.open(
      path,
      '_blank',
      'location=no,hidespinner=yes,hideurlbar=yes,toolbar=no,disallowoverscroll=yes,clearsessioncache=no,clearcache=no'
    );

    if (onMessage) {
      browser.addEventListener('message', onMessage);
    }
    if (onExit) {
      browser.addEventListener('exit', onExit);
    }

    return browser;
  } catch (error) {
    console.error('Error in EmbeddedBrowser:', error);
    return;
  }
};
