import { Alert, Grid2, styled } from '@mui/material';
import { usePostUserEmailChange } from '@sentinel/hooks';
import { validateEmail } from '@vestwell-frontend/helpers';
import {
  Button,
  Form,
  FormField,
  FormSaveButton,
  ModalBody,
  ModalFooter,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { FormikHelpers } from 'formik';
import { FC, useCallback, useState } from 'react';
import * as yup from 'yup';

import { ChangeLoginEmailState } from '../config/changeLoginEmail';

const schema = yup.object().shape({
  newLoginEmail: validateEmail('Please use standard email format').required(
    'Required'
  )
});

type ChangeLoginEmailProps = {
  onClose: () => void | Promise<void>;
};

const StyledFormField = styled(FormField)({
  width: '100%'
});

export const ChangeLoginEmail: FC<ChangeLoginEmailProps> = props => {
  const ctx = useWizard<ChangeLoginEmailState>();

  const [newEmail, setNewEmail] = useState(ctx.state.newLoginEmail);

  const postUserEmailChange = usePostUserEmailChange({
    mutation: {
      onSuccess: data => {
        console.log(data?.code);
        ctx.setState({ emailVerified: true, newLoginEmail: newEmail });
      }
    }
  });

  const onSubmit = useCallback(
    async (
      values: { newLoginEmail: string },
      formikHelpers: FormikHelpers<{ newLoginEmail: string }>
    ) => {
      setNewEmail(values.newLoginEmail);
      try {
        await postUserEmailChange.mutateAsync({
          data: { email: values.newLoginEmail }
        });
      } catch (e) {
        if (e.status === 409) {
          formikHelpers.setErrors({
            newLoginEmail:
              'This email is already in use. Please contact support if you would like to merge your accounts.'
          });
        }
      }
    },
    []
  );

  return (
    <Form
      className='contents'
      initialValues={{ newLoginEmail: ctx.state.newLoginEmail || '' }}
      onChange={postUserEmailChange.reset}
      onSubmit={onSubmit}
      validateOnMount={false}
      validationSchema={schema}>
      <ModalBody>
        <Grid2 container direction='column' spacing={4}>
          <Grid2
            size={{
              lg: 10,
              md: 12
            }}>
            <Text mb={0}>
              We will send a 6-digit security code to your new login email.
            </Text>
          </Grid2>
          <Grid2
            display='flex'
            flexDirection='column'
            flexGrow={1}
            size={{
              lg: 7,
              md: 9,
              xs: 12
            }}>
            <StyledFormField
              autoFocus
              label='New Login Email'
              name='newLoginEmail'
            />
          </Grid2>
          {postUserEmailChange.error &&
            postUserEmailChange.error?.status !== 409 && (
              <Grid2>
                <Alert
                  classes={{ root: 'flex-shrink inline-flex' }}
                  severity='error'
                  variant='standard'>
                  {postUserEmailChange.error?.message}
                </Alert>
              </Grid2>
            )}
        </Grid2>
      </ModalBody>
      <ModalFooter>
        <Button onClick={props.onClose} variant='text'>
          Cancel
        </Button>
        <FormSaveButton
          disabled={!!postUserEmailChange.error}
          loading={postUserEmailChange.isLoading}
          variant='contained'>
          Next: Verify
        </FormSaveButton>
      </ModalFooter>
    </Form>
  );
};

ChangeLoginEmail.displayName = 'ChangeLoginEmail';
