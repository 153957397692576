import {
  EmailOutlined,
  MoreVert,
  PermPhoneMsgOutlined,
  ScreenshotOutlined
} from '@mui/icons-material';
import { Card, Divider, IconButton, Stack, styled } from '@mui/material';
import {
  GetMfaMethodsResDto,
  useDeleteMfaEntry,
  useGetMfaMethods,
  useGetSessionInfo,
  usePatchMfaIsPrimary
} from '@sentinel/hooks';
import { useNotifications } from '@vestwell-frontend/hooks';
import {
  ActionMenu,
  FormatPhoneNumber,
  IconTooltip,
  Link,
  Pill,
  Text
} from '@vestwell-frontend/ui';

import { FC, useCallback, useState } from 'react';

import { MfaSetupState } from '../config/mfaSetup';
import { MfaEditModal } from './MfaEditModal';
import { MfaSetupModal } from './MfaSetupModal';

const SectionCard = styled(Card)(props => ({
  [props.theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [props.theme.breakpoints.up('sm')]: {
    width: props.theme.spacing(140)
  }
}));

export const MfaSection: FC = () => {
  const notify = useNotifications();

  const user = useGetSessionInfo('user');

  const mfaMethods = useGetMfaMethods({
    query: {
      select: data => ({
        all: data,
        formatted: data.reduce(
          (acc, method) => {
            if (method.entryType === 'phone') {
              return {
                ...acc,
                phone: (acc.phone ?? []).concat(method)
              };
            }

            return {
              ...acc,
              [method.entryType]: method
            };
          },
          {} as {
            app: GetMfaMethodsResDto;
            email: GetMfaMethodsResDto;
            phone: GetMfaMethodsResDto[];
          }
        )
      })
    }
  });

  const [isEditModal, setIsEditModal] = useState<{
    mfaEntryId: number;
    type: MfaSetupState['method'];
    value: string;
  }>(null);

  const patchMfaIsPrimary = usePatchMfaIsPrimary({
    mutation: {
      onSuccess: async data => {
        notify.success(
          `${
            data.entryType === 'app' ? 'App based' : 'Phone based'
          } 2FA set as default`
        );

        await mfaMethods.refetch();
      }
    }
  });

  const deleteMfaEntry = useDeleteMfaEntry({
    mutation: {
      onSuccess: async data => {
        notify.success(
          `${
            data.entryType === 'app' ? 'App based' : 'Phone based'
          } 2FA removed`
        );

        await mfaMethods.refetch();
      }
    }
  });

  const onEditClose = useCallback(async () => {
    setIsEditModal(null);
  }, []);

  const onSuccess = useCallback(async () => {
    await user.refetch();
    await mfaMethods.refetch();
  }, []);

  return (
    <SectionCard data-component='section' data-testid='mfa'>
      <Text color='grey50' component='h2' mb={0} variant='f2'>
        Two-Factor Authentication
        <IconTooltip aria-label='More Info on Two-Factor Authentication'>
          In addition to entering your password, at the time of login we will
          send you a 1-time temporary code via mobile phone number. All you have
          to do is enter this code on our verification screen.
        </IconTooltip>
      </Text>
      <Text color='grey100' mb={4} variant='i1'>
        Add an extra layer of security to your account.
      </Text>
      <Stack divider={<Divider />} spacing={2}>
        <Stack data-component='mfaMethod' data-testid='app'>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'>
            <Stack alignItems='center' direction='row' spacing={2.5}>
              <ScreenshotOutlined color='grey400' fontSize='small' />
              <Text color='grey50' mb={0} variant='g2'>
                Authentication App
              </Text>
              {!mfaMethods.data?.formatted?.app && (
                <Pill
                  backgroundColor='ash'
                  backgroundColorOpacity={0.3}
                  fontColor='grey200'
                  value='RECOMMENDED'
                />
              )}
              {!!mfaMethods.data?.formatted?.app && (
                <Pill
                  backgroundColor='mint'
                  backgroundColorOpacity={0.3}
                  fontColor='plantation'
                  value='ENABLED'
                />
              )}
              {!!mfaMethods.data?.formatted?.app?.isPrimary && (
                <Pill
                  backgroundColor='ash'
                  backgroundColorOpacity={0.3}
                  fontColor='grey200'
                  value='DEFAULT'
                />
              )}
            </Stack>
            {!mfaMethods.data?.formatted?.app && (
              <MfaSetupModal method='app' onSuccess={onSuccess} />
            )}
            {!!mfaMethods.data?.formatted?.app && (
              <ActionMenu
                actions={[
                  ...(mfaMethods.data.all.length > 1
                    ? [
                        {
                          content: 'Remove',
                          id: 'removeApp',
                          meta: {
                            mfaEntryId: mfaMethods.data.formatted.app.mfaEntryId
                          },
                          onSelect: async meta => {
                            await deleteMfaEntry.mutateAsync({
                              mfaEntryId: meta.mfaEntryId
                            });
                          }
                        }
                      ]
                    : []),
                  {
                    content: 'Edit',
                    id: 'editApp',
                    meta: {
                      mfaEntryId: mfaMethods.data.formatted.app.mfaEntryId,
                      type: 'app',
                      value: mfaMethods.data.formatted.app.value
                    },
                    onSelect: (meta: any) => {
                      setIsEditModal({
                        mfaEntryId: meta.mfaEntryId,
                        type: meta.type,
                        value: meta.value
                      });
                    }
                  },
                  ...(mfaMethods.data.all.length > 1 &&
                  !mfaMethods.data.formatted.app.isPrimary
                    ? [
                        {
                          content: 'Set as default',
                          id: 'toggleAppPrimary',
                          meta: {
                            mfaEntryId: mfaMethods.data.formatted.app.mfaEntryId
                          },
                          onSelect: async meta => {
                            await patchMfaIsPrimary.mutateAsync({
                              mfaEntryId: meta.mfaEntryId
                            });
                          }
                        }
                      ]
                    : [])
                ]}
                title='Trigger'
                trigger={
                  <IconButton
                    aria-label='App Options'
                    color='primary'
                    data-testid='appOptions'>
                    <MoreVert fontSize='small' />
                  </IconButton>
                }
              />
            )}
          </Stack>
          <Text color='grey200' mb={0} ml={7.5} variant='i1'>
            ex.{' '}
            <Link
              data-testid='googleAuthenticatorLink'
              external
              to='https://support.google.com/accounts/answer/1066447'>
              Google Authenticator
            </Link>
            ,&nbsp;
            <Link
              data-testid='microsoftAuthenticatorLink'
              external
              to='https://support.microsoft.com/en-au/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a'>
              Microsoft Authenticator
            </Link>
          </Text>
        </Stack>
        <Stack data-component='mfaMethod' data-testid='phone'>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'>
            <Stack alignItems='center' direction='row' spacing={2.5}>
              <PermPhoneMsgOutlined color='grey400' fontSize='small' />
              <Text color='grey50' mb={0} variant='g2'>
                Phone Numbers
              </Text>
              {!!mfaMethods.data?.formatted?.phone?.length && (
                <Pill
                  backgroundColor='mint'
                  backgroundColorOpacity={0.3}
                  fontColor='plantation'
                  value='ENABLED'
                />
              )}
            </Stack>
            {!mfaMethods.data?.formatted?.phone?.length && (
              <MfaSetupModal method='phone' onSuccess={onSuccess} />
            )}
          </Stack>
          <Stack mb={1} ml={7.5}>
            <Text color='grey200' mb={0} variant='i1'>
              Verify your identity via a text message or voice call.
            </Text>
            <Text color='grey200' mb={0} variant='i1'>
              Standard rates may apply.
            </Text>
            {mfaMethods.data?.formatted?.phone?.length && (
              <Divider sx={{ mt: 2 }} />
            )}
          </Stack>
          {mfaMethods.data?.formatted?.phone?.length && (
            <Stack divider={<Divider />} ml={7.5} spacing={1}>
              {mfaMethods.data.formatted.phone.map(phone => (
                <Stack
                  alignItems='center'
                  direction='row'
                  justifyContent='space-between'
                  key={phone.value}>
                  <Stack alignItems='center' direction='row' spacing={2.5}>
                    <Text color='grey50' mb={0} variant='g2'>
                      <FormatPhoneNumber mask value={phone.value} />
                    </Text>
                    {!!phone.isPrimary && (
                      <Pill
                        backgroundColor='ash'
                        backgroundColorOpacity={0.3}
                        fontColor='grey200'
                        value='DEFAULT'
                      />
                    )}
                  </Stack>
                  <ActionMenu
                    actions={[
                      ...(mfaMethods.data.all.length > 1
                        ? [
                            {
                              content: 'Remove',
                              id: 'removePhone',
                              meta: {
                                mfaEntryId: phone.mfaEntryId
                              },
                              onSelect: async meta => {
                                await deleteMfaEntry.mutateAsync({
                                  mfaEntryId: meta.mfaEntryId
                                });
                              }
                            }
                          ]
                        : []),
                      {
                        content: 'Edit',
                        id: 'edit',
                        meta: {
                          mfaEntryId: phone.mfaEntryId,
                          type: phone.entryType,
                          value: phone.value
                        },
                        onSelect: (meta: any) => {
                          setIsEditModal({
                            mfaEntryId: meta.mfaEntryId,
                            type: meta.type,
                            value: meta.value
                          });
                        }
                      },
                      ...(mfaMethods.data.all.length > 1 && !phone.isPrimary
                        ? [
                            {
                              content: 'Set as default',
                              id: 'togglePrimaryPhone',
                              meta: {
                                mfaEntryId: phone.mfaEntryId
                              },
                              onSelect: async meta => {
                                await patchMfaIsPrimary.mutateAsync({
                                  mfaEntryId: meta.mfaEntryId
                                });
                              }
                            }
                          ]
                        : [])
                    ]}
                    title='Trigger'
                    trigger={
                      <IconButton
                        aria-label='Phone Options'
                        color='primary'
                        data-testid='phoneOptions'>
                        <MoreVert fontSize='small' />
                      </IconButton>
                    }
                  />
                </Stack>
              ))}
              {mfaMethods.data?.formatted?.phone?.length === 1 && (
                <MfaSetupModal isBackup method='phone' onSuccess={onSuccess} />
              )}
            </Stack>
          )}
        </Stack>
        {!!mfaMethods.data?.formatted?.email && (
          <Stack data-component='mfaMethod' data-testid='email'>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'>
              <Stack alignItems='center' direction='row' spacing={2.5}>
                <EmailOutlined color='grey400' fontSize='small' />
                <Text color='grey50' mb={0} variant='g2'>
                  Email
                </Text>
                <Pill
                  backgroundColor='mint'
                  backgroundColorOpacity={0.3}
                  fontColor='plantation'
                  value='ENABLED'
                />
              </Stack>
              <ActionMenu
                actions={[
                  {
                    content: 'Remove',
                    id: 'removeEmail',
                    meta: {
                      mfaEntryId: mfaMethods.data.formatted.email.mfaEntryId
                    },
                    onSelect: async (meta: any) => {
                      await deleteMfaEntry.mutateAsync({
                        mfaEntryId: meta.mfaEntryId
                      });
                    }
                  }
                ]}
                title='Trigger'
                trigger={
                  <IconButton
                    aria-label='Email Options'
                    color='primary'
                    data-testid='emailOptions'>
                    <MoreVert fontSize='small' />
                  </IconButton>
                }
              />
            </Stack>
            <Stack ml={7.5}>
              <Text color='grey50' mb={2} variant='g2'>
                {mfaMethods.data.formatted.email.value}
              </Text>
              <Text color='grey200' mb={0} variant='i1'>
                This option will soon be removed. Please set up an alternative
                method
              </Text>
            </Stack>
          </Stack>
        )}
      </Stack>
      <MfaEditModal
        isOpen={!!isEditModal}
        method={isEditModal?.type}
        mfaEntryId={isEditModal?.mfaEntryId}
        onClose={onEditClose}
        onSuccess={onSuccess}
        value={isEditModal?.value}
      />
    </SectionCard>
  );
};

MfaSection.displayName = 'MfaSection';
