import { formatPhoneNumber, maskPhoneNumber } from '@vestwell-frontend/helpers';

import { FC, useMemo } from 'react';

export type FormatPhoneNumberProps = {
  mask?: boolean;
  showPlaceholder?: boolean;
  value: string;
};

export const FormatPhoneNumber: FC<FormatPhoneNumberProps> = ({
  value = '',
  ...props
}) => {
  const formatted = useMemo(() => {
    const cleanedValue = value?.toString()?.replace(/-/g, '');

    return cleanedValue
      ? props.mask
        ? maskPhoneNumber(cleanedValue)
        : formatPhoneNumber(cleanedValue)
      : props.showPlaceholder
        ? '--'
        : '';
  }, [props.showPlaceholder, value]);

  return <>{formatted}</>;
};

FormatPhoneNumber.displayName = 'FormatPhoneNumber';
