import {
  AppBar,
  AppBarProps,
  Container,
  ContainerProps,
  styled
} from '@mui/material';

import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export type TopbarProps = DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
> & {
  'data-component'?: string;
};

const TopbarStyled = styled(AppBar)<AppBarProps>(() => ({
  backgroundColor: 'rgba(255,255,255,1)',
  boxShadow: '0 0 #0000, 0 0 #0000, 1px 3px 5px #72727226',
  gridArea: 'topBar',
  paddingTop: `env(safe-area-inset-top)`,
  zIndex: 120
}));

const ContainerStyled = styled(Container)<ContainerProps>(props => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  height: props.theme.spacing(16),
  justifyContent: 'space-between',
  maxWidth: '100%',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
  zIndex: 120,
  [props.theme.breakpoints.down('lg')]: {
    maxWidth: '100%'
  },
  [props.theme.breakpoints.up('lg')]: {
    maxWidth: '100%'
  }
}));

export const Topbar: FC<TopbarProps> = props => {
  return (
    <TopbarStyled as='div' data-component='topBar'>
      <ContainerStyled>{props.children}</ContainerStyled>
    </TopbarStyled>
  );
};
