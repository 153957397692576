import { Card, styled } from '@mui/material';
import { useGetSessionInfo } from '@sentinel/hooks';
import { Text, VerticalAttribute } from '@vestwell-frontend/ui';

import { FC } from 'react';

import { ChangeLoginEmailModal } from './ChangeLoginEmailModal';
import { PasswordResetModal } from './PasswordResetModal';

const SectionCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(140)
  }
}));

const StyledVerticalAttribute = styled(VerticalAttribute)({
  marginBottom: 0
});

export const LoginSettingsSection: FC = () => {
  const user = useGetSessionInfo('user');

  return (
    <SectionCard data-component='section' data-testid='password'>
      <Text color='grey50' component='h2' variant='f2'>
        Login Settings
      </Text>
      <StyledVerticalAttribute label='Login Email' value={user.data?.email} />
      <ChangeLoginEmailModal onSuccess={user.refetch} />
      <PasswordResetModal />
    </SectionCard>
  );
};

LoginSettingsSection.displayName = 'LoginSettingsSection';
