import { useGetWhiteLabel } from '@portal-middleware/hooks';

import { useEffect } from 'react';

export const useDocumentTitle = (
  title: string | undefined,
  ...args: string[]
) => {
  const whitelabel = useGetWhiteLabel();

  useEffect(() => {
    if (!whitelabel.error && !!whitelabel.data?.metadata?.title) {
      document.title = title
        ? [title, ...args, whitelabel.data.metadata.title].join(' / ')
        : whitelabel.data.metadata.title;
    }
  }, [title, args, whitelabel.error, whitelabel.data?.metadata?.title]);
};
