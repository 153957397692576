import { Device } from '@capacitor/device';

export const isNativeMobile = async () => {
  const deviceInfo = await Device.getInfo();
  return ['ios', 'android'].includes(deviceInfo?.platform);
};

export const isEmbeddedBrowser = async () => {
  const isNative = await isNativeMobile();
  //@ts-ignore
  return !isNative && !!window?.webkit?.messageHandlers?.cordova_iab;
};
