import { useRef } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export function useNav(): {
  to: NavigateFunction;
  external: (url: string) => void;
} {
  const navigate = useNavigate();

  const nav = useRef({
    external: url =>
      window.location.assign(
        `${url.startsWith('http') ? '' : window.location.origin}${url}`
      ),
    to: navigate
  });

  return nav.current;
}
