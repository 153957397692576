import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { isPlainObject } from 'lodash';
import { nanoid } from 'nanoid';
import qs from 'qs';
import stringify from 'safe-stable-stringify';

import { getErrorCode, HttpClientError } from './errorCodes';

export type QueryParams<T = Record<string, unknown>> = T &
  Partial<{
    sort?: string | { name?: string };
    order?: 'descending' | 'ascending';
  }>;

const pending = {};

export function client<T>({
  url,
  params,
  ...config
}: AxiosRequestConfig): Promise<T> {
  const queryParams = {};
  const keys = Object.keys(params ?? {});

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const rawValue = params[key];
    const isSortObject =
      key === 'sort' && isPlainObject(rawValue) && rawValue.name;

    const value = isSortObject
      ? rawValue.order === 'descending'
        ? `-${rawValue.name}`
        : rawValue.name
      : params[key];

    if ([undefined, null, ''].every(v => value !== v)) {
      queryParams[key] = value;
    }

    if (Array.isArray(value)) {
      queryParams[key] = value.join(',');
    }
  }

  const key = stringify({
    data: config.data,
    params: queryParams,
    url
  });

  if (pending[key]) {
    return pending[key];
  }

  const traceId = nanoid();
  const source = axios.CancelToken.source();

  const request = axios({
    ...config,
    cancelToken: source.token,
    headers: {
      ...(config.headers as Record<string, string>),
      'x-trace-id': traceId
    },
    params: queryParams,
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat' })
    },
    url: url
  });

  pending[key] = request
    .then(({ data }) => {
      pending[key] = null;

      return Promise.resolve(data);
    })
    .catch((error: AxiosError<any>) => {
      pending[key] = null;

      const status = error.status ?? error?.response?.status ?? 500;

      return Promise.reject(
        new HttpClientError({
          code: getErrorCode(error?.response?.data ?? {}),
          headers: error?.config?.headers as Record<string, string>,
          message:
            error?.response?.data?.message ||
            error?.message ||
            'Internal Server Error',
          meta: error?.response?.data?.meta,
          method: error?.config?.method,
          name: 'fetch',
          status,
          traceId,
          url: error?.config?.url
        }) as unknown as Error
      );
    });

  return pending[key];
}
