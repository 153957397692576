import { ArrowDropDownOutlined } from '@mui/icons-material';
import { Grid2 } from '@mui/material';

import { FC } from 'react';

type SelectChevronProps = {
  className?: string;
};

export const SelectChevron: FC<SelectChevronProps> = props => (
  <Grid2
    alignItems='center'
    className={props.className}
    display='flex'
    height='1em'>
    <ArrowDropDownOutlined />
  </Grid2>
);
