import {
  makeListBuilderSchema,
  validateAddress,
  validateEin,
  validatePhone,
  validateZip
} from '@vestwell-frontend/helpers';

import { format, lastDayOfMonth } from 'date-fns';
import { useMemo } from 'react';
import * as yup from 'yup';

const lastDayOfFebruary = `2/${format(
  lastDayOfMonth(new Date(new Date().getFullYear(), 1, 1)),
  'd'
)}`;

export const fiscalYearEnds = [
  { label: '12/31', value: '12/31' },
  { label: '11/30', value: '11/30' },
  { label: '10/31', value: '10/31' },
  { label: '9/30', value: '9/30' },
  { label: '8/31', value: '8/31' },
  { label: '7/31', value: '7/31' },
  { label: '6/30', value: '6/30' },
  { label: '5/31', value: '5/31' },
  { label: '4/30', value: '4/30' },
  { label: '3/31', value: '3/31' },
  { label: lastDayOfFebruary, value: lastDayOfFebruary },
  { label: '1/31', value: '1/31' }
];

export const useValidateOnboardingQuestionnaire = (args: {
  isMepTpa?: boolean;
  isStarter401kPlan?: boolean;
  isTpa?: boolean;
}) => {
  return useMemo(
    () =>
      yup.object({
        authSignerFirstName: yup
          .string()
          .nullable()
          .required('First Name is required'),

        authSignerLastName: yup
          .string()
          .nullable()
          .required('Last Name is required'),

        authSignerWorkPhone: validatePhone('Invalid phone number')
          .nullable()
          .required('Phone is required'),

        authorizedSignerEmail: yup
          .string()
          .nullable()
          .email('Invalid email')
          .required('Email is required'),

        companyEntityType: yup
          .string()
          .nullable()
          .required('Company Entity Type is required'),

        companyName: yup
          .string()
          .nullable()
          .required('Company Legal Name is required'),

        companyPhoneNumber: validatePhone('Invalid phone number')
          .nullable()
          .required('Phone is required'),

        dayToDayContactEmail: yup
          .string()
          .nullable()
          .email('Invalid email')
          .required('Email is required'),

        dayToDayContactLastName: yup
          .string()
          .nullable()
          .required('Last Name is required'),

        dayToDayContactName: yup
          .string()
          .nullable()
          .required('First Name is required'),

        dayToDayContactNumber: validatePhone('Invalid phone number')
          .nullable()
          .required('Phone is required'),

        doingBusinessAs: yup.string().nullable(),

        employerIdentificationNumber: validateEin('Must be a valid EIN')
          .nullable()
          .required('EIN is required'),

        fiscalYearEnd: yup
          .string()
          .oneOf(fiscalYearEnds.map(({ value }) => value))
          .nullable()
          .required('Fiscal Year End is required'),

        ...(!args.isTpa &&
          !args.isStarter401kPlan && {
            $adoptionAgreementDoc: yup
              .array()
              .nullable()
              .test({
                test: function (value) {
                  if (
                    !value?.length &&
                    this.parent.isConvert === 'true' &&
                    !['SEP_IRA', 'SIMPLE_IRA'].includes(
                      this.parent.existingPlanType
                    )
                  ) {
                    return this.createError({
                      message: 'Document is required',
                      path: this.path
                    });
                  }

                  return true;
                }
              }),

            // only used for internal questionnaire state
            $priorYear5500Doc: yup.array().nullable().optional(),

            anticipatedHires: yup
              .number()
              .nullable()
              .required('This field is required'),

            contributionScenario: yup
              .string()
              .nullable()
              .when('isConvert', {
                is: v => v !== 'true',
                then: yup.string().nullable().required('This field is required')
              }),

            currentComplianceIssues: yup
              .array()
              .of(
                yup
                  .object({
                    label: yup.string(),
                    value: yup
                      .string()
                      .oneOf([
                        'missing_5500',
                        'late_deposits',
                        'missing_contributions',
                        'failed_testing',
                        'other'
                      ])
                  })
                  .nullable()
              )
              .when('isConvert', {
                is: 'false',
                then: yup.array().nullable().optional()
              }),

            existingPlanProvider: yup
              .string()
              .nullable()
              .when('isConvert', {
                is: 'true',
                then: yup
                  .string()
                  .nullable()
                  .required('Current Provider is required')
              }),

            existingPlanType: yup
              .string()
              .nullable()
              .when('isConvert', {
                is: 'true',
                then: yup
                  .string()
                  .oneOf([
                    '401k',
                    '403b',
                    'SIMPLE_IRA',
                    'SEP_IRA',
                    'defined_benefit',
                    'cash_balance',
                    'other'
                  ])
                  .nullable()
                  .required('Plan Type is required')
              }),

            highlyCompensatedEmployees: yup
              .number()
              .nullable()
              .required('This field is required'),

            industryGroup: yup
              .string()
              .nullable()
              .when('isConvert', {
                is: v => v !== 'true',
                then: yup.string().nullable().required('This field is required')
              }),

            isConvert: yup
              .string()
              .oneOf(['true', 'false'])
              .nullable()
              .required('This field is required'),

            ownerEmployees: yup
              .number()
              .nullable()
              .required('This field is required'),

            payrollProviderId: yup
              .string()
              .nullable()
              .required('Payroll Provider is required'),

            payrollSystemOther: yup
              .string()
              .nullable()
              .when('payrollProviderId', {
                is: '6', // id for 'Other'
                then: yup
                  .string()
                  .nullable()
                  .required('Payroll System is required')
              }),

            relativesOfOwnerEmployees: yup
              .number()
              .nullable()
              .required('This field is required'),

            retirementObjectives: yup
              .string()
              .nullable()
              .when('isConvert', {
                is: v => v !== 'true',
                then: yup.string().nullable().required('This field is required')
              })
          }),
        ...(!args.isMepTpa && {
          controlGroupBusinesses: yup.array().when('isPartOfControlGroup', {
            is: true,
            then: makeListBuilderSchema(
              yup
                .object({
                  business: yup.string().required('Company Name is required'),
                  ein: validateEin().required('EIN is required')
                })
                .required()
            )
          }),

          isPartOfControlGroup: yup
            .boolean()
            .nullable()
            .required('This field is required'),

          totalEmployeesNumber: yup
            .number()
            .nullable()
            .required('Number of employees is required')
        }),

        physicalAddress1: validateAddress()
          .nullable()
          .required('Physical Address is required'),

        physicalAddress2: yup.string().nullable(),

        physicalCity: yup.string().nullable().required('City is required'),

        physicalState: yup.string().nullable().required('State is required'),

        physicalZip: validateZip().nullable().required('Zip is required'),

        stateOfIncorporation: yup
          .string()
          .nullable()
          .required('State of Incorporation is required'),

        wiredAtWork: yup.boolean().nullable().required('This field is required')
      }),
    [args.isMepTpa, args.isStarter401kPlan, args.isTpa]
  );
};
