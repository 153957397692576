import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Alert, Grid2, styled } from '@mui/material';
import { usePostMfaSetup } from '@sentinel/hooks';
import { validatePhone } from '@vestwell-frontend/helpers';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import {
  Button,
  Form,
  FormField,
  FormRadioGroup,
  FormSaveButton,
  ModalBody,
  ModalFooter,
  RadioButton,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import * as yup from 'yup';

import { MfaNudgeState } from '../config/mfaNudge';

const schema = yup.object().shape({
  phoneNumber: validatePhone('Must be a valid US phone number'),
  type: yup.string()
});

const StyledFormField = styled(FormField)(({ theme }) => ({
  marginTop: theme.spacing(10),
  width: '100%'
}));

export const MfaNudgePhoneSetupPage: FC = () => {
  const ctx = useWizard<MfaNudgeState>();

  useDocumentTitle(ctx.step.title as string);

  const setup = usePostMfaSetup({
    mutation: {
      onSuccess: res => {
        console.log(res.code);
        ctx.setState({
          hasSetupMfaMethod: true,
          //@ts-expect-error: waiting for hooks
          mfaMethodSubType: res.entrySubType,
          mfaPhone: res.phoneNumber
        });
      }
    }
  });

  const onBack = useCallback(async () => {
    ctx.setState({ isMfaMethodSelected: false });
  }, []);

  const onSubmit = useCallback(async values => {
    await setup.mutateAsync({
      data: {
        subType: values.type,
        type: 'phone',
        value: values.phoneNumber
      }
    });
  }, []);

  return (
    <Form
      className='contents'
      initialValues={{
        phoneNumber: ctx.state.mfaPhone || '',
        type: ctx.state.mfaMethodSubType ?? 'text'
      }}
      onChange={setup.reset}
      onSubmit={onSubmit}
      validateOnMount
      validationSchema={schema}>
      <ModalBody>
        <Grid2
          alignItems='center'
          columns={12}
          container
          direction='column'
          spacing={4}>
          <Grid2
            alignItems='center'
            display='flex'
            flexDirection='column'
            size={{
              lg: 12
            }}>
            <Text
              align='center'
              color='oxfordBlue'
              component='span'
              mb={2}
              variant='f2'>
              Two-Factor Authentication
            </Text>
            <Text align='center' color='oxfordBlue' variant='b2'>
              {ctx.step.title}
            </Text>
          </Grid2>
          <Grid2
            size={{
              lg: 10,
              md: 12
            }}>
            <Text align='center' mb={0}>
              We will provide you a 6-digit security code via the method you
              prefer.
            </Text>
          </Grid2>
          <Grid2
            alignItems='center'
            display='flex'
            flexDirection='column'
            flexGrow={1}
            size={{
              lg: 7,
              md: 9,
              xs: 12
            }}>
            <StyledFormField
              autoFocus
              format='phoneUs'
              label='Mobile Phone Number'
              name='phoneNumber'
            />
          </Grid2>
          <Grid2
            display='flex'
            flexDirection='column'
            flexGrow={1}
            size={{
              lg: 7,
              md: 9,
              xs: 12
            }}>
            <FormRadioGroup
              label='Verification Method'
              name='type'
              variant='standard'>
              <RadioButton label='Via text message' value='text' />
              <RadioButton label='Via voice call' value='voice' />
            </FormRadioGroup>
          </Grid2>
          {setup.error && (
            <Grid2
              alignItems='center'
              display='flex'
              flexDirection='column'
              size={{
                lg: 12
              }}>
              <Alert severity='error' variant='standard'>
                {setup.error?.message}
              </Alert>
            </Grid2>
          )}
        </Grid2>
      </ModalBody>
      <ModalFooter justifyBetween noBoxShadow>
        <Button onClick={onBack} variant='text'>
          Back
        </Button>
        <FormSaveButton
          data-testid='nextVerify'
          disabled={!!setup.error}
          endIcon={<KeyboardArrowRightOutlined fontSize='small' />}>
          Next: Verify
        </FormSaveButton>
      </ModalFooter>
    </Form>
  );
};

MfaNudgePhoneSetupPage.displayName = 'MfaNudgePhoneSetupPage';
