import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';

import { isNativeMobile } from './isNativeMobile';

export const initializeSplashScreen = async (): Promise<void> => {
  const isNative = await isNativeMobile();

  if (isNative && Capacitor.isPluginAvailable('SplashScreen')) {
    try {
      await SplashScreen.show({
        autoHide: true
      });
      App.addListener('appStateChange', async ({ isActive }) => {
        if (isActive) {
          await SplashScreen.hide();
        } else {
          await SplashScreen.show();
        }
      });
    } catch (error) {
      console.error('Error managing splash screen:', error);
    }
  }
};
