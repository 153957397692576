import {
  DetailedHTMLProps,
  FC,
  ImgHTMLAttributes,
  useCallback,
  useState
} from 'react';

export type SuspenseImageProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export const SuspenseImage: FC<SuspenseImageProps> = ({
  alt = '',
  src,
  crossOrigin = null,
  ...rest
}) => {
  const [imageUrl, setImageUrl] = useState(src || rest['data-defaultsrc']);

  const onError = useCallback(() => {
    setImageUrl(rest['data-defaultsrc']);
  }, [rest['data-defaultsrc']]);

  return (
    <img
      {...rest}
      alt={alt}
      crossOrigin={crossOrigin}
      onError={onError}
      src={imageUrl}
    />
  );
};
