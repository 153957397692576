import { Card, Divider, Stack, styled } from '@mui/material';
import { useGetLoginLog } from '@sentinel/hooks';
import { getTimeAgo } from '@vestwell-frontend/helpers';
import { Pill, PLACEHOLDER, Text } from '@vestwell-frontend/ui';

import { FC, useEffect } from 'react';

const SectionCard = styled(Card)(props => ({
  [props.theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [props.theme.breakpoints.up('sm')]: {
    width: props.theme.spacing(140)
  }
}));

export const RecentLoginsSection: FC = () => {
  const loginLog = useGetLoginLog(
    { lastAttemptsCount: 5 },
    {
      query: {
        refetchInterval: 60000, // Refetch every 1 minute (60000 milliseconds)
        refetchOnWindowFocus: true,
        select: data =>
          data?.map(log => ({
            ...log,
            formattedCreatedAt: getTimeAgo(log.createdAt)
          })) ?? []
      }
    }
  );

  useEffect(() => {
    // Effect runs when loginLog.dataUpdatedAt is changing
    // This will cause the component to re-render to show correct dates
  }, [loginLog.dataUpdatedAt]);

  return (
    <SectionCard data-component='section' data-testid='recentLogins'>
      <Text color='grey50' component='h2' variant='f2'>
        Recent Logins
      </Text>
      <Stack data-componet='recentLoginsList' role='list' spacing={4}>
        {loginLog.data?.map((log, index) => (
          <Stack
            data-componet='recentLoginsLog'
            data-testid={log.createdAt}
            key={log.createdAt}
            role='listitem'>
            <Stack direction='row' justifyContent='space-between'>
              <Text
                color='grey50'
                data-componet='recentLoginsLogBrowser'
                mb={0}
                variant='g2'>
                {log.browser ?? PLACEHOLDER}
              </Text>
              {index === 0 && (
                <Pill
                  backgroundColor='sky'
                  fontColor='blueSapphire'
                  value='Current Session'
                />
              )}
            </Stack>
            <Stack
              direction='row'
              divider={<Divider flexItem orientation='vertical' />}
              spacing={2}>
              <Text
                color='grey200'
                data-componet='recentLoginsLogIpAddress'
                mb={0}
                variant='i1'>
                IP: {log.ipAddress ?? PLACEHOLDER}
              </Text>
              <Text
                color='grey200'
                data-componet='recentLoginsLogCreatedAt'
                mb={0}
                variant='i1'>
                {log.formattedCreatedAt}
              </Text>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </SectionCard>
  );
};

RecentLoginsSection.displayName = 'RecentLoginsSection';
