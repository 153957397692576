import { Alert, Stack, styled } from '@mui/material';
import { useGetSessionInfo, usePutResetPassword } from '@sentinel/hooks';
import { validatePassword } from '@sentinel/utils';
import {
  PasswordCheckList,
  useAuth,
  validatePasswords
} from '@vestwell-frontend/elements';
import {
  useDocumentTitle,
  useNotifications,
  useSearchParams,
  useToggle
} from '@vestwell-frontend/hooks';
import { Form, FormField, Text } from '@vestwell-frontend/ui';

import { useCallback, useEffect, useId, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { SubmitButton } from '../components';
import { routes } from '../router/routes';

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(0),
  marginTop: theme.spacing(0),
  width: 'fit-content'
}));

const PasswordFormField = styled(FormField)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: theme.spacing(64)
}));

const ConfirmPasswordFormField = styled(FormField)(({ theme }) => ({
  width: theme.spacing(64)
}));

const StyledForm = styled(Form)(({ theme }) => ({
  width: theme.spacing(64)
}));

export function ResetPasswordPage() {
  useDocumentTitle('Reset Password');

  const describedById = useId();

  const [startRequests, toggleStartRequests] = useToggle(false);

  const navigate = useNavigate();
  const notify = useNotifications();

  const params = useSearchParams();

  const auth = useAuth();

  const sessionInfo = useGetSessionInfo('password-reset', {
    query: {
      enabled: startRequests,
      refetchOnWindowFocus: true,
      useErrorBoundary: true
    }
  });

  const resetPassword = usePutResetPassword({
    mutation: {
      onSuccess: () => {
        notify.success('Password reset successful');
        auth.setToken('');
        navigate(routes.SIGN_IN);
      }
    }
  });

  useEffect(() => {
    if (params.token) {
      auth.setToken(params.token);
      toggleStartRequests(true);

      window.history.replaceState({}, '', routes.RESET_PASSWORD);
    }
  }, []);

  const validate = useMemo(
    () =>
      validatePasswords(
        yup.object().shape({
          password: validatePassword([sessionInfo.data?.email || '']).required(
            'Required'
          ),
          passwordConfirm: yup
            .string()
            .oneOf([yup.ref('password'), undefined], 'Passwords Must Match')
        })
      ),
    [sessionInfo.data?.email]
  );

  const onSubmit = useCallback(async values => {
    await resetPassword.mutateAsync({
      data: {
        password: values.password
      }
    });
  }, []);

  return (
    <>
      <Text
        align='center'
        color='emphasize'
        data-testid='resetPasswordTitle'
        mb={8}
        variant='b2'>
        Reset Password
      </Text>
      <StyledForm
        initialValues={{
          password: '',
          passwordConfirm: ''
        }}
        onSubmit={onSubmit}
        validate={validate}>
        <PasswordFormField
          aria-describedby={describedById}
          label='Password'
          name='password'
          revealable
          type='password'
          width={64}
        />
        <ConfirmPasswordFormField
          label='Confirm Password'
          name='passwordConfirm'
          type='password'
          width={64}
        />
        <Stack>
          {resetPassword.error && (
            <StyledAlert severity='error'>
              {resetPassword.error?.message}
            </StyledAlert>
          )}
          <SubmitButton>Save New Password</SubmitButton>
          <PasswordCheckList describedById={describedById} />
        </Stack>
      </StyledForm>
    </>
  );
}

ResetPasswordPage.displayName = 'ResetPasswordPage';
