import { CircularProgress, Stack, StackProps } from '@mui/material';

import { FC } from 'react';

export type LoaderProps = {
  'data-testid'?: string;
  position?: StackProps['position'];
};

export const Loader: FC<LoaderProps> = props => (
  <Stack
    alignItems='center'
    aria-busy
    bottom={0}
    data-component='Loader'
    data-testid={props['data-testid']}
    justifyContent='center'
    left={0}
    position={props.position || 'absolute'}
    right={0}
    top={0}>
    <CircularProgress />
  </Stack>
);

Loader.displayName = 'Loader';
