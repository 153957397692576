import { StyledEngineProvider } from '@mui/material';
import { MaterialThemeProvider } from '@vestwell-frontend/style';

import { FC, memo, ReactNode } from 'react';

export type ThemeProviderProps = {
  isWhitelabeled?: boolean;
  children: ReactNode;
};

export const ThemeProvider: FC<ThemeProviderProps> = memo(props => {
  return (
    <StyledEngineProvider injectFirst>
      <MaterialThemeProvider isWhitelabeled={props.isWhitelabeled}>
        {props.children}
      </MaterialThemeProvider>
    </StyledEngineProvider>
  );
});

ThemeProvider.displayName = 'ThemeProvider';
