import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths
} from 'date-fns';

const formatTimeAgo = (n: number, unit: string) => {
  return `${n} ${unit}${n !== 1 ? 's' : ''} ago`;
};

export const getTimeAgo = (date: string) => {
  if (!date) {
    return '--';
  }

  const dateLeft = new Date();
  const dateRight = new Date(date);

  const minutes = differenceInMinutes(dateLeft, dateRight);
  const hours = differenceInHours(dateLeft, dateRight);
  const days = differenceInDays(dateLeft, dateRight);
  const months = differenceInMonths(dateLeft, dateRight);

  if (months > 0) {
    return formatTimeAgo(months, 'month');
  } else if (days > 0) {
    return formatTimeAgo(days, 'day');
  } else if (hours > 0) {
    return formatTimeAgo(hours, 'hour');
  } else if (minutes > 0) {
    return formatTimeAgo(minutes, 'min');
  } else {
    return 'Just now';
  }
};
