import { createSvgIcon, SvgIconProps } from '@mui/material';

import { FC } from 'react';

export const ContributionsIcon: FC<SvgIconProps> = createSvgIcon(
  <svg viewBox='0 0 32 32'>
    <path
      clipRule='evenodd'
      d='M20.9522 10.789C20.6617 10.2768 20.1566 9.98718 19.6363 9.98737H15.3041C15.3041 9.98737 15.3042 9.98737 15.3041 9.98737C15.0264 9.98749 14.7506 10.0695 14.5077 10.2289L11.1799 13.5715C10.8877 13.8651 10.4128 13.8661 10.1192 13.5739C9.8257 13.2817 9.82464 12.8068 10.1169 12.5132L13.4942 9.12086C13.5241 9.09077 13.5566 9.06328 13.5912 9.03869C14.0937 8.68153 14.6897 8.48754 15.3037 8.48737H19.6358C19.6359 8.48737 19.6357 8.48737 19.6358 8.48737C20.7264 8.48707 21.7149 9.09381 22.2565 10.0483C22.2567 10.0485 22.2564 10.048 22.2565 10.0483L22.6677 10.7702C22.6677 10.7703 22.6676 10.7701 22.6677 10.7702C23.1996 11.7032 23.2424 12.855 22.784 13.8273C22.7839 13.8273 22.784 13.8272 22.784 13.8273L22.4115 14.6193C21.81 15.8994 20.4495 16.6322 19.0686 16.3381C19.0684 16.3381 19.0687 16.3381 19.0686 16.3381L15.366 15.5512L15.3637 15.5529C14.889 15.8865 14.6077 16.4912 14.6688 17.1327L14.669 17.1344C14.6778 17.229 14.7322 17.281 14.7694 17.2966C14.7696 17.2967 14.7693 17.2966 14.7694 17.2966L21.0582 19.8682C21.0582 19.8682 21.0581 19.8682 21.0582 19.8682C21.377 19.9985 21.7253 20.01 22.0498 19.902L28.7637 17.6724C29.1568 17.5419 29.5813 17.7547 29.7118 18.1478C29.8424 18.5409 29.6295 18.9654 29.2364 19.096L22.5234 21.3253C21.8578 21.5468 21.1402 21.5222 20.4906 21.2566L14.2004 18.6845L14.1987 18.6838C13.6125 18.4423 13.2327 17.8866 13.1755 17.2741C13.0664 16.1258 13.5618 14.988 14.4973 14.3283C14.8318 14.0883 15.2507 13.9951 15.6588 14.0799L15.6621 14.0806L19.3804 14.8709C20.0335 15.0101 20.7283 14.6744 21.054 13.9813L21.4268 13.1884C21.6779 12.656 21.6534 12.0197 21.3647 11.5133L20.9522 10.789z'
      fill='currentColor'
      fillRule='evenodd'
      role='presentation'
    />
    <path
      clipRule='evenodd'
      d='M22.475 4.25622C23.1114 4.21466 23.7409 4.38231 24.2777 4.73069L24.2803 4.73241L29.4098 8.09229C29.7563 8.31926 29.8532 8.78414 29.6262 9.13064C29.3992 9.47713 28.9344 9.57404 28.5879 9.34707L23.4611 5.98892C23.4607 5.98867 23.4603 5.98842 23.4599 5.98817C23.1893 5.81287 22.8798 5.73285 22.5721 5.75307L22.5698 5.75323L14.3123 6.26965L14.3105 6.26976C13.9755 6.28989 13.6494 6.42928 13.3864 6.67592C13.3861 6.67621 13.3858 6.6765 13.3854 6.6768L9.27084 10.5707C9.06584 10.7641 8.90805 11.0158 8.81829 11.303L8.81772 11.3048L8.02397 13.8219C7.89939 14.2169 7.47816 14.4362 7.08313 14.3116C6.68809 14.187 6.46884 13.7658 6.59341 13.3708L7.38659 10.8555C7.38669 10.8552 7.3865 10.8558 7.38659 10.8555C7.55084 10.3305 7.84409 9.85465 8.24115 9.47999C8.24133 9.47983 8.24098 9.48016 8.24115 9.47999L12.3586 5.58333C12.8693 5.10376 13.5241 4.81454 14.2196 4.77252C14.2199 4.7725 14.2192 4.77254 14.2196 4.77252L22.4737 4.2563C22.4742 4.25628 22.4746 4.25625 22.475 4.25622z'
      fill='currentColor'
      fillRule='evenodd'
      role='presentation'
    />
    <path
      clipRule='evenodd'
      d='M9.40652 16.0162H11.8007C12.215 16.0162 12.5507 16.3519 12.5507 16.7662C12.5507 17.1804 12.215 17.5162 11.8007 17.5162H9.40283C9.04075 17.5149 8.67459 17.7934 8.58998 18.2589C8.50434 18.7301 8.75267 19.1592 9.10962 19.3103L9.11119 19.3109L11.5384 20.3443C12.5844 20.7918 13.143 21.9355 12.9416 23.0432C12.7393 24.1564 11.8056 25.0178 10.6559 25.0187H8.27294C7.85873 25.0187 7.52294 24.6829 7.52294 24.2687C7.52294 23.8545 7.85873 23.5187 8.27294 23.5187H10.6548C11.0165 23.5184 11.3813 23.2396 11.4658 22.7749C11.5512 22.3049 11.3047 21.8761 10.9489 21.7236C10.9487 21.7235 10.949 21.7237 10.9489 21.7236L8.52518 20.6917C8.5249 20.6916 8.52546 20.6918 8.52518 20.6917C7.47513 20.247 6.9124 19.1007 7.11416 17.9906C7.31693 16.8751 8.25439 16.0129 9.40652 16.0162z'
      fill='currentColor'
      fillRule='evenodd'
      role='presentation'
    />
    <path
      clipRule='evenodd'
      d='M10.0368 15.0783C10.451 15.0783 10.7868 15.4141 10.7868 15.8283V16.7661C10.7868 17.1803 10.451 17.5161 10.0368 17.5161C9.62258 17.5161 9.28679 17.1803 9.28679 16.7661V15.8283C9.28679 15.4141 9.62258 15.0783 10.0368 15.0783z'
      fill='currentColor'
      fillRule='evenodd'
      role='presentation'
    />
    <path
      clipRule='evenodd'
      d='M10.0368 23.5186C10.451 23.5186 10.7868 23.8544 10.7868 24.2686V25.2065C10.7868 25.6207 10.451 25.9565 10.0368 25.9565C9.62258 25.9565 9.28679 25.6207 9.28679 25.2065V24.2686C9.28679 23.8544 9.62258 23.5186 10.0368 23.5186z'
      fill='currentColor'
      fillRule='evenodd'
      role='presentation'
    />
    <path
      clipRule='evenodd'
      d='M3.20623 16.5519C4.85779 13.3589 8.31526 11.6659 11.72 12.4569C12.1235 12.5506 12.3746 12.9537 12.2808 13.3571C12.1871 13.7606 11.7841 14.0117 11.3806 13.918C8.68657 13.2921 5.89546 14.6177 4.53856 17.241C3.17669 19.874 3.61192 23.1444 5.58711 25.2667C7.55165 27.3775 10.5655 27.8504 13.006 26.4581C15.4601 25.058 16.786 22.0816 16.2094 19.1281C16.13 18.7215 16.3952 18.3276 16.8018 18.2482C17.2083 18.1689 17.6022 18.4341 17.6816 18.8406C18.3739 22.3866 16.7962 26.0227 13.7493 27.761C10.6889 29.507 6.91572 28.8959 4.48909 26.2886C2.0731 23.6927 1.55964 19.7353 3.20623 16.5519z'
      fill='currentColor'
      fillRule='evenodd'
      role='presentation'
    />
  </svg>,
  'ContributionsIcon'
);

ContributionsIcon.displayName = 'ContributionsIcon';
