let callCount = 0;
export const setEmbeddedHeaders = axios => {
  // This event is fired by the core app upon creating an embedded browser
  const handleDeviceInfo = event => {
    const deviceInfo = event.detail;
    const deviceHeader = `${deviceInfo.model}::${deviceInfo.platform}::${deviceInfo.osVersion}`;
    axios.defaults.headers.common['x-vw-mobile-device'] = deviceHeader;
    window.removeEventListener('vw_device_info', handleDeviceInfo, false);
  };
  if (callCount === 0) {
    window.addEventListener('vw_device_info', handleDeviceInfo);
    callCount++;
  }
};
