import { Box } from '@mui/material';
import type { HttpErrorType } from '@vestwell-frontend/hooks';

import { Component, FC, Fragment, ReactNode, Suspense } from 'react';

import { Loader } from './Loader';
import { Page } from './Page';
import { StatusCodeError } from './StatusCodeError';

type ApiBoundaryState = {
  error?: Partial<HttpErrorType>;
  code?: string;
  status?: number;
};

export type ApiBoundaryProps = {
  children?: ReactNode;
  hidePage?: boolean;
  isLoading?: boolean;
  error?: Partial<HttpErrorType>;
};

export class ApiBoundary extends Component<ApiBoundaryProps, ApiBoundaryState> {
  state = {
    code: undefined,
    error: undefined,
    status: undefined
  };

  static getDerivedStateFromError(error) {
    return {
      code: error.code,
      error,
      status: error.status ?? 500
    };
  }

  render() {
    const error = this.state.error || this.props.error;
    const isPage = !this.props.hidePage;
    const Wrapper = isPage ? Page : Fragment;

    if (error && error?.status !== 401) {
      return (
        <Wrapper>
          {isPage ? (
            <Box
              alignItems='center'
              bottom={0}
              display='flex'
              height='100%'
              justifyContent='center'
              left={0}
              position='absolute'
              right={0}
              top={0}
              width='100%'>
              <StatusCodeError code={error.code} status={error.status} />
            </Box>
          ) : (
            <StatusCodeError code={error.code} status={error.status} />
          )}
        </Wrapper>
      );
    }

    if (this.props.isLoading) {
      return (
        <Wrapper>
          <Loader position={isPage ? 'absolute' : 'relative'} />
        </Wrapper>
      );
    }

    return (
      <Suspense
        fallback={
          <Wrapper>
            <Loader position={isPage ? 'absolute' : 'relative'} />
          </Wrapper>
        }>
        {this.props.children}
      </Suspense>
    );
  }
}

export function withApiBoundary<Props>(
  Component,
  apiBoundaryProps?: ApiBoundaryProps
): FC<Props> {
  const Wrapper: FC<Props> = props => (
    <ApiBoundary {...apiBoundaryProps}>
      <Component {...props} />
    </ApiBoundary>
  );

  Wrapper.displayName = 'ApiBoundary';

  return Wrapper;
}
