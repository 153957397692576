import { useGetUserinfo } from '@sentinel/hooks';

import { useMemo } from 'react';
import { useLocation } from 'react-use';

export function useSubdomain(): string {
  const location = useLocation();
  const user = useGetUserinfo();

  return useMemo(
    // @ts-ignore
    () => user.data?.subDomain ?? location.host?.split('.')[0],
    [location.host, user.data]
  );
}
