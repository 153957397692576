import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import Cookies from 'js-cookie';

export const getBearerToken = async (isMobile: boolean) => {
  if (isMobile) {
    try {
      const tokenData = await SecureStoragePlugin.get({ key: 'token' });
      const tokenObject = tokenData?.value ? JSON.parse(tokenData.value) : null;
      return tokenObject?.access_token && tokenObject?.token_type
        ? `${tokenObject.token_type} ${tokenObject.access_token}`
        : null;
    } catch (e) {
      console.warn('SecureStoragePlugin.get failed', e);
    }
  } else {
    const bearerToken = Cookies.get('token');
    return bearerToken && bearerToken?.indexOf('Bearer') === 0
      ? bearerToken
      : null;
  }
};
