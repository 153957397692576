export type HttpErrorType = Error & {
  code?: string;
  headers?: Record<string, unknown>;
  message: string;
  meta?: Record<string, unknown>;
  method?: string;
  name: string;
  status?: number;
  traceId?: string;
  url?: string;
};

export class HttpError extends Error {
  code?: string;
  headers?: Record<string, unknown>;
  message: string;
  meta?: Record<string, unknown>;
  method?: string;
  name: string;
  status?: number;
  traceId?: string;
  url?: string;

  constructor(props: HttpErrorType) {
    super(props.message);
    this.code = props.code;
    this.headers = props.headers;
    this.name = props.name;
    this.message = props.message;
    this.status = props.status;
    this.traceId = props.traceId;
    this.meta = props.meta;
    this.method = props.method;
    this.url = props.url;
  }
}

export class HttpClientError extends HttpError {
  constructor(props?: HttpErrorType) {
    super({
      ...props,
      message: props?.message,
      name: 'HttpClientError',
      status: props?.status
    });
  }
}

export function getErrorCode(
  body: Record<string, unknown>,
  defaultCode?: string
) {
  return (
    body.errors?.[0]?.code ?? body.code ?? defaultCode ?? 'UNKNOWN_EXCEPTION'
  );
}
