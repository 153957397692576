import { Alert, Grid2, styled } from '@mui/material';
import { usePatchUserEmail, usePostUserEmailChange } from '@sentinel/hooks';
import { useAuth } from '@vestwell-frontend/elements';
import { useNotifications } from '@vestwell-frontend/hooks';
import {
  Button,
  Form,
  FormField,
  FormSaveButton,
  ModalBody,
  ModalFooter,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { useCallback } from 'react';
import * as yup from 'yup';

import { ChangeLoginEmailState } from '../config/changeLoginEmail';
import { ResendOtpButton } from './ResendOtpButton';

const StyledFormField = styled(FormField)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: theme.spacing(50)
}));

const schema = yup.object().shape({
  securityCode: yup
    .string()
    .min(6, 'Must be at least 6 digits')
    .required('Required')
});

export const ChangeLoginEmailVerifyCode = () => {
  const { setToken } = useAuth();

  const ctx = useWizard<ChangeLoginEmailState>();
  const notify = useNotifications();

  const postUserEmailChange = usePostUserEmailChange({
    mutation: {
      onSuccess: data => {
        console.log(data?.code);
      }
    }
  });

  const patchUserEmail = usePatchUserEmail({
    mutation: {
      onError: () => {
        notify.error('Couldn’t update your login email at this time');
      },
      onSuccess: data => {
        ctx.setState({
          codeVerified: true
        });

        setToken(data.token);

        notify.success('Successfully updated login email');
      }
    }
  });

  const onBack = useCallback(() => {
    ctx.setState({
      emailVerified: false
    });
  }, []);

  const onResendCode = useCallback(async () => {
    await postUserEmailChange.mutateAsync({
      data: {
        email: ctx.state.newLoginEmail
      }
    });
  }, [ctx.state]);

  const onSubmit = useCallback(async values => {
    await patchUserEmail.mutateAsync({
      data: {
        token: values.securityCode
      }
    });
  }, []);

  const isDisabled = patchUserEmail.error?.message?.startsWith(
    'Your account has been temporarily locked'
  );

  return (
    <Form
      className='contents'
      onChange={patchUserEmail.reset}
      onSubmit={onSubmit}
      validateOnMount={false}
      validationSchema={schema}>
      <ModalBody>
        <Grid2 alignItems='center' container direction='column' spacing={4}>
          <Grid2
            alignItems='center'
            display='flex'
            flexDirection='column'
            size={{
              lg: 12
            }}>
            <Text mb={0} textAlign='center'>
              Enter the 6-digit code sent to{' '}
              <strong>{ctx.state.newLoginEmail}</strong>. The code will expire
              in 10 minutes
            </Text>
          </Grid2>
          <Grid2
            alignItems='center'
            display='flex'
            flexDirection='column'
            flexGrow={1}
            justifyContent='center'
            size={{
              lg: 12
            }}>
            <StyledFormField
              align='center'
              autoComplete='one-time-code'
              autoFocus
              disabled={isDisabled}
              format='numericString'
              label='Security Code'
              maxLength={6}
              name='securityCode'
              type='tel'
              variant='large'
            />
            <ResendOtpButton
              disabled={isDisabled}
              inputName='emailVerification'
              onClick={onResendCode}
              variant='inline'
            />
          </Grid2>
          {(postUserEmailChange.error || patchUserEmail.error) && (
            <Grid2>
              <Alert
                classes={{ root: 'flex-shrink inline-flex' }}
                severity='error'
                variant='standard'>
                {postUserEmailChange.error?.message ??
                  patchUserEmail.error?.message}
              </Alert>
            </Grid2>
          )}
        </Grid2>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isDisabled} onClick={onBack} variant='text'>
          Back
        </Button>
        <FormSaveButton disabled={isDisabled || !!patchUserEmail.error}>
          Confirm
        </FormSaveButton>
      </ModalFooter>
    </Form>
  );
};

ChangeLoginEmailVerifyCode.displayName = 'ChangeLoginEmailVerifyCode';
