import type {
  MutateOptions,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query';
import { useMutation as useRqMutation } from '@tanstack/react-query';

import type { AxiosRequestConfig } from 'axios';

import { client } from './utils/client';
import type { HttpErrorType } from './utils/errorCodes';

type UseMutateResult<Response, HttpErrorType> = [
  (
    variables?: any,
    options?: MutateOptions<Response, HttpErrorType, any>
  ) => Promise<Response>,
  Omit<UseMutationResult<Response, HttpErrorType, any, any>, 'mutateAsync'>
];

type SubmitParams = Omit<AxiosRequestConfig, 'url'>;

export function usePatchMutation<Response = Record<string, any>>(
  url,
  options?: SubmitParams,
  config?: Omit<
    UseMutationOptions<Response, HttpErrorType, any, any>,
    'mutationKey' | 'mutationFn'
  >
): UseMutateResult<Response, HttpErrorType> {
  const { mutateAsync, ...result } = useRqMutation<
    Response,
    HttpErrorType,
    any,
    any
  >(
    data =>
      client<Response>({
        ...options,
        data,
        method: 'PATCH',
        url
      }),
    config
  );

  return [mutateAsync, result];
}

export function usePostMutation<Response = Record<string, any>>(
  url,
  options?: SubmitParams,
  config?: Omit<
    UseMutationOptions<Response, HttpErrorType, any, any>,
    'mutationKey' | 'mutationFn'
  >
): UseMutateResult<Response, HttpErrorType> {
  const { mutateAsync, ...result } = useRqMutation<
    Response,
    HttpErrorType,
    any,
    any
  >(
    data =>
      client<Response>({
        ...options,
        data,
        method: 'POST',
        url
      }),
    config
  );

  return [mutateAsync, result];
}

export function usePutMutation<Response = Record<string, any>>(
  url,
  options?: SubmitParams,
  config?: Omit<
    UseMutationOptions<Response, HttpErrorType, any, any>,
    'mutationKey' | 'mutationFn'
  >
): UseMutateResult<Response, HttpErrorType> {
  const { mutateAsync, ...result } = useRqMutation<
    Response,
    HttpErrorType,
    any,
    any
  >(data => client<Response>({ ...options, data, method: 'PUT', url }), config);

  return [mutateAsync, result];
}
