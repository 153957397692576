import { Box, Divider, Stack, styled } from '@mui/material';
import { Link, Text } from '@vestwell-frontend/ui';

import { FC } from 'react';

import { useConfig } from '../contexts';

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(0, 2)
}));

export const Help: FC = () => {
  const config = useConfig();
  if (!config.login?.contacts?.length && !config.login?.resources?.length)
    return null;

  return (
    <Stack alignItems='center' my={8} textAlign='center'>
      {!!config.login?.resources?.length && (
        <Stack
          bgcolor={theme => theme.palette.white.main}
          borderRadius={4}
          direction='row'
          justifyContent='center'
          mb={2}
          px={4}
          py={1}
          width='fit-content'>
          {config.login.resources.map((resource, index) => (
            <Stack
              alignItems='center'
              direction='row'
              key={resource.description}>
              <Link external to={resource.url}>
                {resource.description}
              </Link>
              {index !== config.login.resources.length - 1 && (
                <StyledDivider flexItem orientation='vertical' />
              )}
            </Stack>
          ))}
        </Stack>
      )}
      {!!config.login?.contacts?.length && (
        <>
          <Text mb={2} mt={4}>
            Need Help?
          </Text>
          {config.login.contacts.map(contact => (
            <Box
              bgcolor={theme => theme.palette.white.main}
              borderRadius={4}
              key={contact.description}
              mb={2}
              px={4}
              py={1}
              width='fit-content'>
              {contact.description}{' '}
              <Link to={`mailto:${contact.email}`} type='email'>
                {contact.email}
              </Link>{' '}
              or call{' '}
              <Link to={`tel:${contact.phone}`} type='phone'>
                {contact.phone}
              </Link>
            </Box>
          ))}
        </>
      )}
    </Stack>
  );
};

Help.displayName = 'Help';
