import { styled } from '@mui/material';
import { FormSaveButton, FormSaveButtonProps } from '@vestwell-frontend/ui';

import { FC } from 'react';

const StyledFormSaveButton = styled(FormSaveButton)(({ theme }) => ({
  margin: theme.spacing(8, 0)
}));

export const SubmitButton: FC<FormSaveButtonProps> = props => {
  return <StyledFormSaveButton fullWidth {...props} />;
};

SubmitButton.displayName = 'SubmitButton';
