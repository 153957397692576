import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FC, ReactNode } from 'react';

const StyledPage = styled(Grid)(props => ({
  alignItems: 'center',
  flexGrow: 1,
  flexWrap: 'nowrap',
  height: `calc(100% - ${props.theme.spacing(16)})`,
  justifyContent: 'start',
  overflowY: 'auto'
}));

export type PageProps = {
  children: ReactNode;
};

export const Page: FC<PageProps> = props => {
  return (
    <StyledPage container data-component='page' direction='column'>
      {props.children}
    </StyledPage>
  );
};

Page.displayName = 'Page';
