import isPropValid from '@emotion/is-prop-valid';
import { styled } from '@mui/material';

import { FC, HTMLAttributes, ReactNode } from 'react';

import { Text, TextProps } from './Text';

export type ModalHeaderProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  children: ReactNode;
};

const StyledModalHeader = styled('div', {
  shouldForwardProp: isPropValid
})<ModalHeaderProps>(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: props.theme.spacing(6, 10),
  [props.theme.breakpoints.down('sm')]: {
    padding: props.theme.spacing(6)
  }
}));

const StyledModalHeaderText = styled(Text)<TextProps>(props => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: 0,
  width: '100%',
  [props.theme.breakpoints.down('sm')]: {
    justifyContent: 'center'
  }
}));

export const ModalHeader: FC<ModalHeaderProps> = ({ id, ...props }) => {
  return (
    <StyledModalHeader data-component='modalHeader' {...props}>
      <StyledModalHeaderText color='grey100' id={id} variant='e2'>
        {props.children}
      </StyledModalHeaderText>
    </StyledModalHeader>
  );
};

ModalHeader.displayName = 'ModalHeader';
