import { useMemo } from 'react';
import { useSearchParams as useSearchParamsReactRouterDom } from 'react-router-dom';

export const useSearchParams = () => {
  const [searchParams] = useSearchParamsReactRouterDom();

  return useMemo(
    () => (searchParams ? Object.fromEntries([...searchParams]) : {}),
    [searchParams]
  );
};
