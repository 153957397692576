import { WizardStep } from '@vestwell-frontend/ui';

import { MfaSetupApp } from '../components/MfaSetupApp';
import { MfaSetupPhone } from '../components/MfaSetupPhone';
import { MfaSetupVerifyCode } from '../components/MfaSetupVerifyCode';
import { VerifyPassword } from '../components/VerifyPassword';

export type MfaSetupState = {
  methodSelected?: boolean;
  methodVerified?: boolean;
  method?: 'app' | 'email' | 'phone';
  mfaEntryId: number;
  passwordVerified?: boolean;
  subType?: 'text' | 'voice';
  value?: string;
};

export const mfaSetup = {
  initialState: {
    method: undefined,
    methodSelected: false,
    // final step
    methodVerified: false,
    mfaEntryId: null,
    passwordVerified: false,
    value: ''
  },
  steps: [
    {
      Component: VerifyPassword,
      title: 'Verify Your Password',
      when: state => !state.passwordVerified
    },
    {
      Component: MfaSetupApp,
      title: 'Setup Authentication App',
      when: state => state.passwordVerified && state.method === 'app'
    },
    {
      Component: MfaSetupPhone,
      title: 'Enter Phone Number',
      when: state => state.passwordVerified && state.method === 'phone'
    },
    {
      Component: MfaSetupVerifyCode,
      title: 'Verify Security Code',
      when: state => state.methodSelected
    }
  ] as WizardStep<MfaSetupState>[]
};
