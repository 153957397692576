import { styled } from '@mui/material';

import { FC, ReactNode } from 'react';

export type PageFooterProps = {
  /** Rendered within bar */
  children?: ReactNode;
  /** Style for container */
  className?: string;
  /** Containing element to render in DOM */
  role?: string;
  sticky?: boolean;
};

const StylePageFooter = styled('div', {
  shouldForwardProp: prop => prop !== 'sticky'
})<{ sticky?: boolean }>(({ theme, sticky }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(4),
  width: '100%',
  ...(sticky
    ? {
        backgroundColor: theme.palette.white.main,
        bottom: 0,
        boxShadow: '1px -3px 5px #72727226',
        marginLeft: theme.spacing(-12),
        marginRight: theme.spacing(-12),
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),
        position: 'sticky',
        width: 'unset',
        [theme.breakpoints.down('lg')]: {
          marginLeft: theme.spacing(-10),
          marginRight: theme.spacing(-10),
          paddingLeft: theme.spacing(10)
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: theme.spacing(-4),
          marginRight: theme.spacing(-4),
          paddingLeft: theme.spacing(4)
        }
      }
    : {
        gridArea: 'pageFooter'
      })
}));

export const PageFooter: FC<PageFooterProps> = props => {
  return (
    <StylePageFooter {...props} data-component='pageFooter'>
      {props.children}
    </StylePageFooter>
  );
};

PageFooter.displayName = 'PageFooter';
