import { Alert as MaintenanceAlert } from '@hermes/ui';
import { Alert, Box, Stack, styled } from '@mui/material';
import { usePostLogin } from '@sentinel/hooks';
import { useAuth } from '@vestwell-frontend/elements';
import { validateEmail } from '@vestwell-frontend/helpers';
import {
  useDocumentTitle,
  useNotifications,
  useSearchParams
} from '@vestwell-frontend/hooks';
import { Form, FormField, Link, Text } from '@vestwell-frontend/ui';

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useLocalStorage } from 'usehooks-ts';
import * as Yup from 'yup';

import { SubmitButton } from '../components';
import { useConfig } from '../contexts';
import { routes } from '../router/routes';

const StyledFormField = styled(FormField)(({ theme }) => ({
  width: theme.spacing(64)
}));

const StyledAlert = styled(Alert)(() => ({
  marginTop: 0,
  width: 'fit-content'
}));

export const loginSchema = Yup.object().shape({
  email: validateEmail('Please use standard email format').required('Required'),
  password: Yup.string().required('Required')
});

export function SignInPage() {
  useDocumentTitle('Sign In');

  const notify = useNotifications();
  const navigate = useNavigate();
  const params = useSearchParams();

  const [, setCodeLastSentTime] = useLocalStorage('codeLastSentTime', {});

  const auth = useAuth();
  const config = useConfig();

  const login = usePostLogin({
    mutation: {
      onSuccess: () => {
        window.location.href = `${window.location.origin}${routes.LOGIN_VERIFICATION}`;
      }
    }
  });

  const submitLogIn = useCallback(
    async values => {
      auth.setToken('');
      await login.mutateAsync({
        data: {
          password: values.password,
          redirectParams: params,
          username: values.email
        }
      });
    },
    [params]
  );

  useEffectOnce(() => {
    if (params.error) {
      notify.error(
        'You have been logged out due to inactivity. Please log in again.',
        {
          persist: true
        }
      );

      window.history.replaceState({}, '', routes.SIGN_IN);
    }
    setCodeLastSentTime({});
  });

  useUpdateEffect(() => {
    if (login.error?.code === 'LOCKED_OUT') {
      navigate(routes.ACCOUNT_LOCKED);
    }
  }, [login.error]);

  return (
    <>
      <Text color='emphasize' data-testid='signInTitle' mb={8} variant='b2'>
        Sign In
      </Text>
      <MaintenanceAlert />
      <Form
        initialValues={{
          email: '',
          password: ''
        }}
        onChange={login.reset}
        onSubmit={submitLogIn}
        validationSchema={loginSchema}>
        <Stack spacing={2} width={256}>
          <StyledFormField
            autoComplete='on'
            data-testid='emailField'
            format='email'
            label='Email'
            name='email'
            width={64}
          />
          <StyledFormField
            label='Password'
            name='password'
            revealable
            type='password'
            width={64}
          />
          <Stack alignItems='center'>
            {login.error && (
              <StyledAlert severity='error'>{login.error?.message}</StyledAlert>
            )}
            <Link
              data-testid='forgotPasswordLink'
              to={routes.FORGOT_PASSWORD}
              variant='i1'>
              Forgot your password?
            </Link>
            <SubmitButton disabled={!!login.error}>Sign In</SubmitButton>
          </Stack>
        </Stack>
      </Form>
      <Text color='grey100' variant='i2'>
        {config.login?.accountSetup?.link?.description
          ? `${config.login?.accountSetup?.link?.description} `
          : `Don't have an account yet? `}
        <Box
          color={theme => theme.palette.primary.main}
          component='a'
          data-testid='registrationLink'
          href={`${window.location.href.replace('signin', 'connect')}${
            config.login?.accountSetup
              ? routes.ACCOUNT_SETUP.slice(1)
              : config.registration?.isState
                ? routes.EMPLOYER_REGISTRATION.slice(1)
                : routes.REGISTRATION.slice(1)
          }`}
          sx={theme => theme.typography.i1}>
          {config.login?.accountSetup?.link?.text ?? 'Start here'}
        </Box>
      </Text>
    </>
  );
}

SignInPage.displayName = 'SignInPage';
