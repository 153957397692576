import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Grid2, styled } from '@mui/material';
import { useDocumentTitle } from '@vestwell-frontend/hooks';
import {
  AvatarIcon,
  Button,
  ModalBody,
  ModalFooter,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { MfaNudgeState } from '../config/mfaNudge';
import { routes } from '../router/routes';

const StyledModalBody = styled(ModalBody)({
  background: 'linear-gradient(180deg, #DFF4FF 0%, #FFFFFF 45.01%), #FFFFFF'
});

const StyledAvatarIcon = styled(AvatarIcon)(({ theme }) => ({
  height: theme.spacing(33),
  width: theme.spacing(33)
}));

export function MfaNudgeCompletedPage() {
  const ctx = useWizard<MfaNudgeState>();

  useDocumentTitle(ctx.step.title as string);

  return (
    <>
      <StyledModalBody>
        <Grid2
          alignItems='center'
          columns={12}
          container
          direction='column'
          spacing={2}>
          <Grid2
            display='flex'
            flexDirection='row'
            justifyContent='center'
            size={{
              lg: 12
            }}>
            <StyledAvatarIcon />
          </Grid2>
          <Grid2
            alignItems='center'
            display='flex'
            justifyContent='center'
            size={{
              lg: 9,
              md: 10,
              xs: 12
            }}>
            <Text align='center' variant='b2'>
              {ctx.step.title}
            </Text>
          </Grid2>
          <Grid2
            alignItems='center'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            size={{
              lg: 10,
              xs: 12
            }}>
            <Text align='center'>
              Your account is now more secure. We will prompt you for the
              security code the next time you log in. You can add additional
              methods or change the selected authentication method from the
              security menu.
            </Text>
          </Grid2>
        </Grid2>
      </StyledModalBody>
      <ModalFooter noBoxShadow>
        <Button
          autoFocus
          endIcon={<KeyboardArrowRightOutlined fontSize='small' />}
          to={routes.LOGIN_VERIFICATION}>
          Continue to your portal
        </Button>
      </ModalFooter>
    </>
  );
}

MfaNudgeCompletedPage.displayName = 'MfaNudgeCompletedPage';
