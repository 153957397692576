import { SyntheticEvent, useCallback, useState } from 'react';

type UseToggleEvent =
  | SyntheticEvent
  | KeyboardEvent
  | boolean
  | any
  | ((prev: boolean) => boolean);

type UseToggleFn = (e?: UseToggleEvent) => void;

export function useToggle(initialValue?: boolean): [boolean, UseToggleFn] {
  const [state, setState] = useState(!!initialValue);

  const toggleState = useCallback((e?: UseToggleEvent) => {
    setState(
      typeof e === 'boolean' || typeof e === 'function' ? e : prev => !prev
    );
  }, []);

  return [state, toggleState];
}
