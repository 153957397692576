import { Card, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SuspenseImage } from '@vestwell-frontend/ui';

import { FC, ReactNode } from 'react';

import { useConfig } from '../contexts';

const StyledCard = styled(Card, {
  shouldForwardProp: propName => propName !== 'isLogo'
})<{ isLogo: boolean }>(({ theme, isLogo }) => ({
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  borderTopColor: theme.palette.primary.main,
  borderTopWidth: theme.spacing(0.75),
  display: 'flex',
  flexDirection: 'column',
  minHeight: isLogo ? theme.spacing(152.5) : theme.spacing(132.5),
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: isLogo ? '20%' : undefined,
    width: theme.spacing(110)
  }
}));

const StyledSuspenseImage = styled(SuspenseImage)(({ theme }) => ({
  height: theme.spacing(12),
  marginTop: theme.spacing(8)
}));

export const BackgroundCard: FC<{ children?: ReactNode }> = props => {
  const config = useConfig();

  return (
    <StyledCard isLogo={!!config.login?.logo}>
      {!!config.login?.logo && (
        <StyledSuspenseImage
          alt={`${
            ['connect', 'signin'].includes(config.subdomain)
              ? 'vestwell'
              : config.subdomain
          } logo`}
          data-component='logo'
          // todo:: remove after updating in db
          src={config.login.logo
            .replace('/clients/login/logos', '/login')
            .replace('.png', '.webp')}
        />
      )}
      <Stack alignItems='center' data-component='content' py={8} width='100%'>
        {props.children}
      </Stack>
    </StyledCard>
  );
};

BackgroundCard.displayName = 'BackgroundCard';
