import { PaletteColor, styled, useTheme } from '@mui/material';
import { useDownload } from '@vestwell-frontend/hooks';

import { Children, cloneElement, forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

import { TextProps } from './Text';

function injectLinkedProp(children) {
  if (
    Children.count(children) > 1 ||
    !children.type ||
    children.type.name !== 'Card'
  ) {
    return children;
  }
  return cloneElement(children, { isLinked: true });
}

export type LinkProps = Omit<RouterLinkProps, 'to'> & {
  'data-component'?: string;
  'data-testid'?: string;
  external?: boolean;
  isSecureDownload?: boolean;
  to?: string;
  variant?: TextProps['variant'];
};

const LinkStyled = styled(RouterLink)(props => ({
  color: (props.theme.palette[props.color] as PaletteColor)?.main
}));

const AnchorStyled = styled('a')(props => ({
  color: (props.theme.palette[props.color] as PaletteColor)?.main
}));

AnchorStyled.displayName = 'AnchorStyled';

export const Link = forwardRef<any, LinkProps>(
  (
    {
      external,
      isSecureDownload,
      to,
      children,
      color = 'primary',
      variant,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const isExternal = external || (to && to.includes(':'));
    const target = isExternal ? '_blank' : undefined; // external + URL schemes

    const download = useDownload(to || '');

    if (isExternal || !to) {
      return (
        <AnchorStyled
          {...props}
          color={color}
          data-component={props['data-component'] || 'link'}
          href={isSecureDownload ? '#' : to}
          onClick={isSecureDownload ? download : props.onClick}
          ref={ref}
          sx={theme.typography[variant]}
          target={target}>
          {children}
        </AnchorStyled>
      );
    }

    const linkedChildren = injectLinkedProp(children);

    return (
      <LinkStyled
        {...props}
        color={color}
        data-component={props['data-component'] || 'link'}
        onClick={isSecureDownload ? download : props.onClick}
        ref={ref}
        sx={theme.typography[variant]}
        target={target}
        to={isSecureDownload ? '#' : to}>
        {linkedChildren}
      </LinkStyled>
    );
  }
);

Link.displayName = 'Link';
