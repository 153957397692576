import { Add } from '@mui/icons-material';
import { useCamelCase, useNotifications } from '@vestwell-frontend/hooks';
import {
  Button,
  Modal,
  WizardProvider,
  WizardStep
} from '@vestwell-frontend/ui';

import { FC, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import { mfaSetup, MfaSetupState } from '../config/mfaSetup';

export type MfaSetupModalProps = {
  isBackup?: boolean;
  method: MfaSetupState['method'];
  onSuccess: () => void | Promise<void>;
};

export const MfaSetupModal: FC<MfaSetupModalProps> = props => {
  const notify = useNotifications();

  const [open, toggleOpen] = useToggle(false);
  const [state, setState] = useState<MfaSetupState>(null);
  const [step, setStep] = useState<WizardStep>(null);

  const testId = useCamelCase(`setup ${props.method}`);

  const initialState = useMemo(() => {
    return {
      ...mfaSetup.initialState,
      method: props.method
    };
  }, [props.method]);

  useEffect(() => {
    if (state?.methodVerified) {
      notify.success(
        `${state.method === 'app' ? 'App based' : 'Phone based'} 2FA setup`
      );

      toggleOpen();
      setState(null);

      props.onSuccess();
    }
  }, [state, props.onSuccess]);

  const Component = step?.Component;

  return (
    <>
      {props.isBackup ? (
        <Button
          aria-haspopup='dialog'
          data-testid={testId}
          onClick={toggleOpen}
          startIcon={<Add />}
          variant='inline'>
          Add a Backup Number
        </Button>
      ) : (
        <Button
          aria-haspopup='dialog'
          data-testid={testId}
          onClick={toggleOpen}
          variant='inline'>
          Setup
        </Button>
      )}
      <WizardProvider<MfaSetupState>
        initialState={initialState}
        key={open ? '1' : '0'}
        name='Mfa Setup'
        onStateChange={setState}
        onStepChange={setStep}
        steps={mfaSetup.steps}>
        {step && (
          <Modal
            closeButton
            header={step.title}
            isOpen={open}
            onRequestClose={toggleOpen}>
            {Component && <Component isMfaSetup onClose={toggleOpen} />}
          </Modal>
        )}
      </WizardProvider>
    </>
  );
};

MfaSetupModal.displayName = 'MfaSetupModal';
